import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.register');
import { OnInit, OnDestroy } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-register',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.register/view.scss */
.wrapper {
  background-image: url(/assets/images/background.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.wrapper-box {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.circle {
  background-color: #fff;
  border: 3px solid var(--wc-blue);
  width: 140px;
  height: 140px;
  top: -90px;
  left: calc(50% - 70px);
  border-radius: 70%;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1607843137);
  z-index: 5;
  margin: 0 auto;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle .logo {
  width: 100%;
  max-width: 80px;
  margin: auto;
}
.circle .logo > a {
  width: 80px;
}
.circle .logo img {
  width: 100%;
}

.form {
  padding: 20px 0;
  z-index: 2;
  color: #000000;
  background-color: white;
  border-radius: 16px;
  position: relative;
  padding-bottom: 52px;
}
.form .box-login {
  display: flex;
}
.form input {
  border: 1px solid #000;
}
.form input:disabled {
  border-right-color: #000;
  border-left-color: #000;
  color: var(--wc-gray);
  text-align: center;
}
.form input:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-color: #000;
  font-family: "main-r";
}
.form input:not(:last-child) {
  margin-right: -1px;
}
.form input:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-color: #000;
  font-family: "main-r";
}

.wrapper .form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 15vh 0;
  min-height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 768px) {
  .container {
    padding: 10vh 16px;
  }
}

.box-login {
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 70%;
  margin: auto;
}
@media (max-width: 768px) {
  .box-login {
    width: 85%;
  }
}

.form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border-width: 1px;
  background-color: rgb(255, 255, 255);
  display: block;
  font-size: 18px;
  color: #2b2929;
  transition-duration: 0.25s;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  box-sizing: border-box;
}

.form input:hover {
  background-color: rgb(255, 255, 255);
}

.form input:focus {
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: #000000;
  border-color: #6d8df6;
}

.form input::placeholder {
  color: #ddd;
}

.form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  transition-duration: 0.25s;
  position: relative;
  width: 70%;
  margin: auto;
  padding: 18px 19px 19px;
  box-sizing: border-box;
  font-family: "main-b";
}
@media (max-width: 768px) {
  .form button {
    width: 80%;
  }
}

.addition-info {
  width: 70%;
  margin: auto;
  font-size: 14px;
  display: block;
  margin-top: 24px;
}
@media (max-width: 768px) {
  .addition-info {
    width: 80%;
  }
}
.addition-info a {
  color: var(--wc-text);
  cursor: pointer;
}
.addition-info a:hover {
  color: #6380de;
}

.form button:hover {
  color: var(--wc-blue);
  border: 1px solid var(--wc-blue);
  background-color: rgba(108, 141, 246, 0.2);
}

.hint {
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  color: var(--wc-text);
  background-color: var(--wc-light);
}

.single-sign-on {
  text-align: center;
}
.single-sign-on .card {
  max-width: 320px;
  margin: 12px auto;
  cursor: pointer;
}

.rounded-start,
.rounded-end {
  --tblr-border-radius: 10px;
}

.login-box {
  margin-top: 40px;
}
.login-box input {
  padding: 18px 19px;
}`],
})
export class PageRegisterComponent implements OnInit, OnDestroy {
    constructor(@Inject( Service) public service: Service) { }

    public data = {
        email: '',
        password: '',
        first_name: "",
        last_name: "",
    };

    public async ngOnInit() {
        await this.service.init();

        const s = this.service.auth.session;
        await this.service.auth.allow(false, "/authenticate");

        if (!s.idp) {
            location.href = "/auth/logout";
            return;
        }

        const isdupl = s.isdupl;
        if (isdupl) {
            const uniqueKey = s.unique_key;
            const uniqueValue = s.unique_value;
            const res = await this.service.alert.show({
                title: "ID Linking",
                message: `${uniqueKey}: ${uniqueValue}\n위 정보로 이미 가입된 계정이 존재합니다.\n계정을 연결하시겠습니까?`,
                cancel: "취소",
                actionBtn: "error",
                action: "계정 연결하기",
                status: "error"
            });
            if (res) {
                this.linking = true;
                location.href = "/authenticate";
                return;
            }
        }

        const ui = s.requested_userinfo;
        this.data.email = ui.email;
        this.data.username = ui.email.split("@")[0];
        if (ui.last_name) this.data.last_name = ui.last_name;
        if (ui.first_name) this.data.first_name = ui.first_name;
        await this.service.render();
        this.captchaRefresh();
        await this.service.success("모든 회원은 로컬 계정이 등록되어있어야 합니다.\n로컬 계정 회원가입을 진행합니다.");
    }

    public ngOnDestroy() {
        if (!this.linking)
            wiz.call("session_clear");
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async join() {
        if (this.data.first_name.length < 2) return await this.alert("이름을 입력해주세요");
        if (this.data.last_name.length < 1) return await this.alert("성을 입력해주세요");

        let password = this.data.password;
        let password_re = this.data.password_repeat;
        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        if (!this.data.purpose) return await this.service.error("가입목적을 입력해주세요.");

        let user = this.service.copy(this.data);
        delete user.password_repeat;
        user.password = this.service.auth.hash(user.password);
        delete user.onetimepass;
        if (!user.username) return await this.service.error("ID를 입력해주세요.");

        let { code, data } = await wiz.call("join", user);

        if (code == 200) {
            await this.alert("회원가입 신청이 완료되었습니다.\n관리자의 승인 후 로그인할 수 있습니다.", "success");
            location.reload();
        } else {
            await this.alert(data);
        }
    }

    public captcha = null;
    public async captchaRefresh() {
        this.captcha = null;
        await this.service.render(100);
        this.captcha = this.captchaURL();
        await this.service.render();
    }
    public captchaURL() {
        return wiz.url(`captcha?ts=${+new Date()}`);
    }
}

export default PageRegisterComponent;