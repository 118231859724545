import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.idp');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { SAMLUtil } from "src/libs/portal/keycloud/util/saml";

@Component({
    selector: 'wiz-portal-keycloud-external-idp',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.idp/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn.btn-sm {
  height: 18px;
}

.file-custom {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  border: 1px solid #dddddd;
}
.file-custom strong {
  line-height: 32px;
  border-left: 1px solid #dddddd;
}

.v-center > * {
  display: inline-block;
  vertical-align: middle;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
  border-bottom: 2px solid lightgray;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.justify-item-start {
  justify-items: start;
}

.display-contents {
  display: contents;
}`],
})
export class PortalKeycloudExternalIdpComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any;

    public loaded: boolean = false;
    public samlUtil: SAMLUtil = new SAMLUtil();
    public monacoEditor: any;
    public O = Object;

    public monacoopts: any = {
        value: '',
        language: 'html',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        }
    }

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.src) this.info.src = {};
        if (this.info.src.id) {
            if (this.info.src.type === "federation") {
                this.fedinfo = this.info.src.extra.parsed;
            }
            else if (this.info.src.type === "single") await this.fedParseSingle();
        }
        this.loaded = true;
        await this.service.render();
    }

    public async init(event: any) {
        let { editor } = event;
        this.monacoEditor = editor;
        if (this.info.src && this.info.src.entityid) {
            this.monacoEditor.updateOptions({ readOnly: true });
            if (this.info.src.type === "federation") {
                const el = document.querySelector("nu-monaco-editor");
                el.style.height = "400px";
                el.querySelector(".monaco-editor").style.height = "400px";
            }
        }
    }

    public file = null;
    public async onFileSelect(e) {
        const files = e.target.files;
        if (files.length === 0 || !files) {
            this.file = null;
            return;
        }
        const text = await files[0].text();
        if (!this.isValidXML(text)) {
            await this.service.error("XML 형식의 파일이 아닙니다.");
            this.file = null;
            await this.service.render();
            return;
        }
        this.file = files[0];
        this.service.render();
    }

    public async onFileSelected(event, isNew = false) {
        this.service.file.resize(event.target.files[0], 60, 1.0)
            .then(base64String => {
                if (!isNew)
                    this.info.icon = base64String;
                else
                    this.newItem.icon = base64String;
                this.service.render();
            });
    }

    public isValidXML(text) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "application/xml");
        const err = doc.querySelector("parsererror");
        if (err) return false;
        return true;
    }

    public disabled() {
        if (!this.newItem.name) return;
        if (this.newItem.name.replace(/\s/g, "").length === 0) return;
        if (this.newItem.type === "single") {
            if (this.direct) {
                if (!this.isValidXML(this.newItem.text)) return true;
                return false;
            }
            else {
                if (!this.file) return true;
                return false;
            }
        }
        else { // federation
            return !this.newItem.status;
        }
    }

    public newItem = {
        name: "",
        text: "",
        url: "",
        type: "single",
        status: false,
    };

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }

    public async create() {
        if (!this.newItem.name) return;
        let res;
        if (this.disabled()) return;
        await this.service.loading.show();
        if (this.newItem.type === "single") {
            if (this.direct) {
                res = await wiz.call("enroll", {
                    type: this.newItem.type,
                    name: this.newItem.name,
                    xml: this.newItem.text,
                    icon: this.newItem.icon,
                });
            }
            else {
                const body = new FormData();
                body.append("file[]", this.file);
                body.append("type", this.newItem.type);
                body.append("name", this.newItem.name);
                body.append("icon", this.newItem.icon);
                res = await wiz.call("upload", {}, { body, headers: undefined });
            }
        }
        else { // federation
            res = await wiz.call("federation", {
                type: this.newItem.type,
                name: this.newItem.name,
                url: this.newItem.url,
            });
        }
        await this.service.loading.hide();
        const { code, data } = res;
        if (code !== 200) return await this.service.error("Invalid metadata format");
        location.href = this._url(`/external/saml/idp/${data}/info`);
    }

    public async getFederation(url) {
        if (!url) return;
        if (!url.startsWith("http")) return;
        const { code, data } = await wiz.call("fedinfo", { url });
        if (code !== 200) {
            this.newItem.status = false;
            this.newItem.text = "";
            return await this.service.error(`Code: ${code}, Federation 정보 가져오기 실패!`);
        }
        this.newItem.status = true;
        this.newItem.text = data;
        await this.service.success("Federation 정보 가져오기 성공!");
    }

    public async parse() {
        let util: SAMLUtil = this.samlUtil;
        let metadata = util.parse(this.info.src.metadata);
        if (metadata.service_type != 'idp') {
            await this.service.alert.show({
                title: 'Invalid Metadata',
                message: 'Please set valid IdP metadata.',
                cancel: false,
                actionBtn: 'error',
                action: 'Confirm',
                status: 'error'
            });
            return;
        }
        metadata.metadata = this.info.src.metadata;
        this.info.src = metadata;
        this.info.name = metadata.service_name;
        this.info.servicetype = 'saml';
        this.info.key = metadata.entityid;
        this.info.src.cert = await util.cert(this.info.src.cert.text);

        if (this.monacoEditor)
            this.monacoEditor.updateOptions({ readOnly: true });

        await this.service.render();
    }

    public fedText = "";
    public fed = null;
    public fedinfo = { list: [] };

    public parseEntityDescriptor(idp, namespaces) {
        const md = namespaces["urn:oasis:names:tc:SAML:2.0:metadata"];
        const ds = namespaces["http://www.w3.org/2000/09/xmldsig#"];

        const obj = {
            entityID: idp.getAttribute("entityID"),
            cert: [],
            sls: [],
            sso: [],
            org: {},
            contact: {},
        };

        const certs = idp.getElementsByTagName(`${md}:KeyDescriptor`);
        Array.from(certs).forEach(cert => {
            const use = cert.getAttribute("use");
            const tmp = cert.getElementsByTagName(`${ds}:X509Certificate`);
            if (tmp.length === 0) return;
            obj.cert.push({ use, text: tmp[0].textContent });
        })

        const sls = idp.getElementsByTagName(`${md}:SingleLogoutService`);
        Array.from(sls).forEach(it => {
            const binding = it.getAttribute("Binding");
            const location = it.getAttribute("Location");
            obj.sls.push({ binding, location });
        });

        const sso = idp.getElementsByTagName(`${md}:SingleSignOnService`);
        Array.from(sso).forEach(it => {
            const binding = it.getAttribute("Binding");
            const location = it.getAttribute("Location");
            obj.sso.push({ binding, location });
        });

        const nif = idp.getElementsByTagName(`${md}:NameIDFormat`);
        obj.NameIDFormat = nif.length > 0 ? nif[0].textContent : "-";

        const org = idp.getElementsByTagName(`${md}:Organization`);
        if (org.length > 0) {
            Array.from(org[0].children).forEach(it => {
                obj.org[it.tagName.split(":")[1]] = it.textContent;
            });
        }

        const contact = idp.getElementsByTagName(`${md}:ContactPerson`);
        if (contact.length > 0) {
            Array.from(contact[0].children).forEach(it => {
                obj.contact[it.tagName.split(":")[1]] = it.textContent;
            });
        }
        return obj;
    }

    public async fedParse() {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.info.src.metadata, "application/xml");
        const root = doc.firstElementChild;
        if (!root.tagName.endsWith(":EntitiesDescriptor")) return await this.service.error("Invalid Federation Metadata");

        // 1. get namespaces
        const namespaces = {};
        root.getAttributeNames().forEach(attr => {
            const val = root.getAttribute(attr);
            if (["ID", "Name", "validUntil"].includes(attr)) this.fedinfo[attr] = val;
            else if (attr.startsWith("xmlns:")) {
                const ns = attr.slice("xmlns:".length);
                namespaces[val] = ns;
            }
        });

        // 2. get idp list
        const md = namespaces["urn:oasis:names:tc:SAML:2.0:metadata"];
        const list = [];
        Array.from(root.getElementsByTagName(`${md}:EntityDescriptor`)).forEach(el => {
            const idp = el.getElementsByTagName(`${md}:IDPSSODescriptor`);
            if (idp.length === 0) return;
            list.push(el);
        });

        // 3. make idp info
        Array.from(list).forEach(idp => {
            const obj = this.parseEntityDescriptor(idp, namespaces);
            this.fedinfo.list.push(obj);
        });
        // if (this.fedinfo.list.length > 0) this.fed = this.fedinfo.list[0];
        await this.service.render();
    }

    public async fedParseSingle() {
        const parser = new DOMParser();
        const doc = parser.parseFromString(this.info.src.metadata, "application/xml");
        const root = doc.firstElementChild;
        if (!root.tagName.endsWith(":EntityDescriptor")) return await this.service.error("Invalid Metadata");

        // 1. get namespaces
        const namespaces = {};
        root.getAttributeNames().forEach(attr => {
            const val = root.getAttribute(attr);
            if (attr.startsWith("xmlns:")) {
                const ns = attr.slice("xmlns:".length);
                namespaces[val] = ns;
            }
        });

        // 2. make idp info
        const obj = this.parseEntityDescriptor(root, namespaces);

        this.fedinfo.list.push(obj);
        if (this.fedinfo.list.length > 0) this.fed = this.fedinfo.list[0];
        await this.service.render();
    }

    public filteredList() {
        const t = this.fedText.toLowerCase();
        if (t.length < 4) {
            if (this.fed) return [this.fed];
            return [];
        }
        return this.fedinfo.list.filter(idp => {
            if (this.fed && this.fed.entityID === idp.entityID) return true;
            if (idp.entityID.toLowerCase().includes(t)) return true;
            return false;
        });
    }

    public async update() {
        const body = {
            id: this.info.id,
            name: this.info.name,
            icon: this.info.icon,
        }
        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("저장 성공");
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete SAML IDP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", this.info);
        this.back();
    }

    public back() {
        this.service.href(this._url("/external/saml/idp"));
    }
}

export default PortalKeycloudExternalIdpComponent;