import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.saml.idp.filter');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-saml-idp-filter',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.saml.idp.filter/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table {
  table-layout: fixed;
}
table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}`],
})
export class PortalKeycloudExternalSamlIdpFilterComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() category: any;
    @Input() info: any;

    public limit = 100;
    public list = [];
    public displayList = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load() {
        let denied = [];
        await this.service.loading.show();
        if (this.info.extra.acl) denied = Object.keys(this.info.extra.acl);
        this.list = this.info.src.extra.parsed.list.map(it => {
            const show = !denied.includes(it.entityID);
            const displayOrg = this.displayOrg(it.org);
            return { ...it, displayOrg, show };
        });
        if (this.list.length <= this.limit)
            this.displayList = this.list;
        else
            this.displayList = [];
        await this.service.loading.hide();
    }

    public text = "";
    public async filter() {
        if (this.list.length <= this.limit) {
            if (this.text.length === 0) {
                this.displayList = this.list;
                return await this.service.render();
            }
        }
        else {
            if (this.text.length === 0) {
                this.displayList = [];
                return await this.service.render();
            }
        }
        if (this.text.length < 4) return;
        await this.service.loading.show();
        this.displayList = this.list.filter(it => {
            const st = this.text.toLowerCase();
            const t1 = it.entityID.toLowerCase();
            if (t1.includes(st)) return true;
            const t2 = it.displayOrg.toLowerCase();
            if (t2.includes(st)) return true;
            return false;
        });
        await this.service.loading.hide();
    }

    public displayOrg(org) {
        const name = org.OrganizationDisplayName || org.OrganizationName;
        const url = org.OrganizationURL ? ` (${org.OrganizationURL})` : "";
        return `${name || ""}${url}`;
    }

    public async update() {
        const acl = this.list.reduce((acc, { entityID, show }) => {
            if (!show) acc[entityID] = false;
            return acc;
        }, {});
        const { code } = await wiz.call("update", { id: this.info.id, acl });
        if (code !== 200) return await this.service.error("Error!");
        this.info.extra.acl = acl;
        await this.service.success("Saved!");
        await this.load();
    }
}

export default PortalKeycloudExternalSamlIdpFilterComponent;