import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.discovery');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-discovery',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.discovery/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  min-width: 100px;
  flex-grow: 1 !important;
}
.wiz-modal .modal-content .badge {
  position: relative;
}
.wiz-modal .modal-content .badge .btn-close {
  position: inherit;
  padding: 0 12px !important;
}
.wiz-modal .modal-content .badge .btn-close:hover {
  background-color: inherit;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.w-35 {
  width: 35% !important;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-left {
  justify-self: left;
}

.form-check {
  height: 44px;
  line-height: 44px;
  align-content: center;
  cursor: pointer;
}
.form-check.check-type {
  color: var(--kc-t3);
  border: 1px solid #BFC2C3;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 15px;
}
.form-check.check-type.active {
  color: var(--tblr-primary);
  border-color: var(--tblr-primary);
  background-color: #EDF8FF;
}
.form-check.check-type input[type=checkbox] {
  margin: auto 0;
}

.form-check-radio-group {
  width: fit-content;
  border: 1px solid var(--tblr-primary);
  border-radius: 44px;
  color: var(--kc-t2);
}
.form-check-radio-group .form-check {
  border-radius: 44px;
  width: 185px;
  text-align: center;
}
.form-check-radio-group .form-check.active {
  color: white;
  font-family: main-b;
  background-color: var(--tblr-primary);
}
.form-check-radio-group .form-check .form-check-label {
  font-size: 1rem;
}`],
})
export class PortalKeycloudAdminDiscoveryComponent implements OnInit {

    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public list: any = [];
    public newItem: any = null;

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.loadProviders();
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const body = this.service.copy(this.search);
        const { code, data } = await wiz.call("search", body);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public displayProvider(provider) {
        if (["single", "federation"].includes(provider.saml_type))
            return `[${provider.type.toUpperCase()}/${provider.saml_type.replace(/^[a-z]/, char => char.toUpperCase())}] ${provider.name}`;
        return `[${provider.type.toUpperCase()}] ${provider.name}`
    }

    public modal = {
        mode: null,
        data: null,
    };

    public closeModal() {
        this.modal = {
            mode: null,
            data: null,
        };
        this.service.render();
    }

    public async showModal(mode, data = { name: "", provider: {}, enable: true, extra: {}, type: "top" }) {
        this.modal.mode = mode;
        this.modal.data = this.service.copy(data);
        this.service.render();
    }

    public providers = [];
    public async loadProviders() {
        const { code, data } = await wiz.call("providers");
        if (code !== 200) return await this.service.error("Error");
        this.providers = data.map(it => {
            it.label = this.displayProvider(it);
            return it;
        });
        await this.service.render();
    }

    public options() {
        return this.providers;
    }

    public async remove(item: any) {
        const res = await this.service.alert.show({
            title: "DELETE",
            message: `정말 "${item.name}" Discovery를 삭제하시겠습니까?`,
            action: "delete",
            cancel: "cancel",
        });
        if (!res) return;
        await wiz.call("delete", { id: item.id });
        this.load(this.search.page);
        this.closeModal();
    }

    public async update() {
        const body = this.service.copy(this.modal.data);
        if (body.name.replace(/\s/g, "").length === 0) return await this.service.error("name을 반드시 입력해주세요.");
        await this.service.loading.show();
        const { code, data } = await wiz.call("update", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("저장 실패!");
        await this.service.success("저장 성공!");
        this.closeModal();
        await this.load();
    }
}

export default PortalKeycloudAdminDiscoveryComponent;