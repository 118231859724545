import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.history.consent');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-history-consent',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.history.consent/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PortalKeycloudHistoryConsentComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const dump = 20;
        const startpage = Math.floor((page - 1) / dump) * dump + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async cancelConsent(item) {
        const res = await this.service.alert.show({
            title: "Consent 동의 삭제",
            message: `정말 Consent 동의 내역을 삭제하시겠습니까?`,
            action: "삭제하기",
            cancel: "cancel",
        });
        if (!res) return;
        const body = {
            id: item.id,
        };
        const { code } = await wiz.call("revoke", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Consent 동의 삭제 성공!");
        await this.load(this.search.page);
    }

    public displayClient(client) {
        const t = client.type? `[${client.type.toUpperCase()}] ` : "";
        const n = client.name || "-";
        return `${t}${n}`;
    }

    public displayAttributes(attributes) {
        return Object.keys(attributes).sort().join(", ");
    }
}

export default PortalKeycloudHistoryConsentComponent;