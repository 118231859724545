import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.history');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-history',
template: templateSource || '',
    styles: [``],
})
export class PageHistoryComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/history/agreement");
        if (!['agreement', 'consent'].includes(this.TYPE))
            return service.href("/history/agreement");
    }

    public TYPE: string = '';

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
    }

    public async ngDoCheck() {
        let preType = this.TYPE;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return this.service.href("/history/agreement");
        if (preType != this.TYPE)
            await this.service.render();
    }
}

export default PageHistoryComponent;