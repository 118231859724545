import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.internal.oidc');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-internal-oidc',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.internal.oidc/view.scss */
.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}

wiz-component-pre {
  line-height: normal;
}`],
})
export class PortalKeycloudAdminInternalOidcComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public tab = "op";
    public menus = ["op", "rp"];
    public menuMap = {
        op: "OP 모듈 설정",
        rp: "RP 모듈 설정",
    };
    public show(tab) {
        this.tab = tab;
        try {
            const currentUrl = new URL(`${location.origin}${location.pathname}`);
            currentUrl.searchParams.set("tab", tab);
            history.replaceState({}, "", currentUrl);
        } catch { }
        this.service.render();
    }

    public cert = {
        op: "",
        rp: "",
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/mypage");
        const params = new URLSearchParams(location.search);
        if (this.menus.includes(params.get('tab'))) this.show(params.get('tab'));
        await this.load();
        this.cert.op = await this.getCert("op");
        this.cert.rp = await this.getCert("rp");
        await this.service.render();
    }

    public origin() {
        return location.origin;
    }

    public op = {};
    public rp = {};

    public async getCert(type) {
        const { code, data } = await wiz.call("get_cert", { type });
        if (code !== 200) return "";
        let res = data;
        try {
            res = JSON.stringify(JSON.parse(data), null, 2);
        } catch { }
        return res;
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        Object.entries(data).forEach(([key, value]) => {
            if (key.startsWith("OP_")) {
                const k = key.slice(3).toLowerCase();
                this.op[k] = value;
            }
            else if (key.startsWith("RP_")) {
                const k = key.slice(3).toLowerCase();
                this.rp[k] = value;
            }
        });
        await this.service.render();
    }

    public async save() {
        const body = [];
        Object.entries(this.idp).forEach(([key, value]) => {
            body.push([`OP_${key.toUpperCase()}`, value]);
        });
        Object.entries(this.sp).forEach(([key, value]) => {
            body.push([`RP_${key.toUpperCase()}`, value]);
        });
        await this.service.loading.show();
        const { code } = await wiz.call("save", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }
}

export default PortalKeycloudAdminInternalOidcComponent;