import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.agreement');
import { OnInit, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-agreement',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.agreement/view.scss */
.btn-area button {
  width: 50%;
  height: 50px;
  margin: 0 15px;
  font-size: 24px;
}

label {
  line-height: 44px;
  font-size: 24px;
}
label input[type=checkbox] {
  width: 24px;
  height: 24px;
}
label > * {
  vertical-align: middle;
}

.ck-content {
  padding: 32px !important;
  max-width: 860px;
  margin: 0 auto;
  height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #000;
}
.ck-content h2 {
  margin-top: 0 !important;
}

.content {
  padding-bottom: 40px;
}

.content-inner {
  max-width: 860px;
  margin: 0 auto;
}

.storybook {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 1440px;
  width: 90vw;
  margin: 0px auto;
  height: calc(100% - 24px);
  margin-top: 24px;
  border-top: 1px solid #2b2c2e;
  border-left: 1px solid #2b2c2e;
  border-right: 1px solid #2b2c2e;
}
@media (max-width: 768px) {
  .storybook {
    width: 92vw;
  }
}

.storybook::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: linear-gradient(108deg, rgba(0, 0, 0, 0.7), transparent), url("/assets/images/background.jpg");
}

.content-dark {
  background-color: #2b2c2e !important;
  color: #fff;
}

.content-light {
  background-color: #E9E8EE;
}`],
})
export class PageAgreementComponent implements OnInit {
    @ViewChild("user_agreement") user_agreement;
    @ViewChild("privacy_policy") privacy_policy;
    private id = null;
    public version = null;
    private check = {
        user_agreement: false,
        privacy_policy: false,
    };

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow();
        await this.load();
    }

    private async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return await this.service.error("Not found AGREEMENT.");
        this.id = data.id;
        this.version = data.version;
        this.user_agreement.nativeElement.innerHTML = data.user_agreement;
        this.privacy_policy.nativeElement.innerHTML = data.privacy_policy;
    }

    public async disagree() {
        await wiz.call("disagree", { id: this.id });
        location.href = "/auth/logout";
    }

    public async agree() {
        if (!this.check.user_agreement) {
            await this.service.error(`User Agreement was not agreed upon.`);
            document.querySelector("#check-a").focus();
            return;
        }
        if (!this.check.privacy_policy) {
            await this.service.error(`Privacy Policy was not agreed upon.`);
            document.querySelector("#check-p").focus();
            return;
        }
        const { code } = await wiz.call("agree", { id: this.id, version: this.version });
        if (code !== 200) return await this.service.error("SERVER ERROR. Please contact system administrator.");
        location.href = "/mypage"
    }
}

export default PageAgreementComponent;