import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.internal');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-internal',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.internal/view.scss */
/* src/app/page.admin.internal/view.scss: no such file or directory */`],
})
export class PageAdminInternalComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/admin/internal/attributes");
        if (!['saml', 'oidc'].includes(this.TYPE))
            return service.href("/admin/internal/attributes");
    }

    public TYPE: string = "";

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/mypage");
    }

    public async ngDoCheck() {
        const preType = this.TYPE;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';

        if (preType !== this.TYPE)
            await this.service.render();
    }
}

export default PageAdminInternalComponent;