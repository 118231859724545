import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.authenticate');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-authenticate',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.authenticate/view.scss */
.wrapper {
  background-image: url(/assets/images/bg-login.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .wrapper {
    overflow-y: auto;
  }
}
.wrapper .intro-area {
  padding: 0 8rem;
}
@media screen and (max-width: 1024px) {
  .wrapper .intro-area {
    padding: 0 4rem;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .intro-area {
    padding: 0 1rem;
  }
}
.wrapper .intro-area .title-area .title {
  font-size: 70px;
}
@media screen and (max-width: 1024px) {
  .wrapper .intro-area .title-area .title {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .intro-area .title-area .title {
    display: inline-block;
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .intro-area .title-area .title + .title {
    margin-left: 8px;
  }
}
.wrapper .form-area {
  padding: 0 5rem;
}
@media screen and (max-width: 1024px) {
  .wrapper .form-area {
    padding: 0 3rem;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .form-area {
    padding: 0 1rem;
  }
}
.wrapper .form-area > .card:first-child {
  padding-top: 5rem;
}
@media screen and (max-width: 1024px) {
  .wrapper .form-area > .card:first-child {
    padding-top: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .form-area > .card:first-child {
    padding-top: 0.5rem;
  }
}
.wrapper .form-area > .card * {
  border-radius: 10px;
}
.wrapper .form-area > .card.social-card {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}
.wrapper .base-area {
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1024px) {
  .wrapper .base-area {
    min-width: 100%;
  }
}
.wrapper .idp-area {
  max-height: 300px;
  overflow-y: auto;
}
.wrapper .idp-area .card:hover {
  border-color: var(--tblr-primary);
  font-family: main-b;
}
.wrapper .input-field + .input-field {
  margin-top: 30px;
}
.wrapper input.form-control {
  height: 42px;
  line-height: 42px;
}`],
})
export class PageAuthenticateComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public initiate = null;
    public SAMLRequest = null;
    public token = null;
    public returnTo = null;
    public federations = [];

    public async ngOnInit() {
        await this.service.init();
        const params = new URLSearchParams(document.location.search);

        // is oidc
        this.token = params.get("token");
        let returnTo = params.get("returnTo") || "/mypage";
        if (this.token) returnTo = null;

        // is saml
        this.SAMLRequest = params.get("SAMLRequest");

        if (this.token) this.initiate = "oidc";
        if (this.SAMLRequest) this.initiate = "saml";

        if (this.initiate) {
            const body = { initiate: this.initiate };
            if (this.token) body.token = this.token;
            if (this.SAMLRequest) body.SAMLRequest = this.SAMLRequest;
            await wiz.call("initiate", body);
        }

        const s = this.service.auth.session;

        // linking... - from /mypage
        if (s.linking) {
            await this.service.auth.allow();
        }
        else if (s.isdupl) { // linking from /register
            await this.service.auth.allow();
            this.onlyLocal = true;
        }
        else {
            // 세션 확인
            const check = await this.service.auth.allow(false);
            if (check) this.returnTo = returnTo; // 세션 없음
            else {
                if (!this.initiate) {
                    const href = this.returnTo || "/mypage";
                    location.href = href;
                    return;
                }
                // 세션 존재할 시 proc로 이동
                const body = {
                    initiate: this.initiate,
                };
                if (this.token) body.token = this.token;
                const res = await wiz.call("authproc_start", body);
                if (res.code !== 200) return await this.service.error("Error!");
                location.href = res.data;
                return;
            }
        }
        if (!this.onlyLocal) {
            await this.getProviders();
        }
        window.addEventListener("popstate", e => {
            e.preventDefault();
            if (this.view !== "login") this.show("login");
        });
        await this.service.render();
    }

    public idp = {
        common: [],
        social: [],
    };
    public async getProviders() {
        const { code, data } = await wiz.call("providers");
        if (code !== 200) return;
        if (Array.isArray(data)) {
            this.idp.common = data;
            this.idp.social = [];
        }
        else {
            const { common, social } = data;
            this.idp.common = common;
            this.idp.social = social;
        }
        await this.service.render();
    }

    public view: string = 'login';
    public data = this.emptyData();
    public emptyData() {
        return {
            username: '',
            email: '',
            password: '',
            first_name: "",
            last_name: "",
        }
    }

    public async show(view) {
        if (this.view === view) return;
        this.view = view;
        window.history.pushState(null, '', window.location.href);

        switch (view) {
            case "login":
                this.data = this.emptyData();
                break;
            case "join":
                this.captchaRefresh();
                break;
            case "password/reset":
                this.data = this.emptyData();
                this.verifyKey = null;
                break;
        }

        await this.service.render();
    }

    public async login() {
        if (!this.data.username) return;
        if (!this.data.password) return;

        const user = {
            username: this.data.username,
            password: this.service.auth.hash(this.data.password),
        };
        if (this.initiate) user.initiate = this.initiate;
        if (this.token) user.token = this.token;

        const { code, data } = await wiz.call("login", user);
        if (code !== 200) return await this.alert(data, 'error');

        if (this.initiate && typeof data === "string") {
            location.href = data;
            return;
        }
        const href = this.returnTo || "/mypage";
        location.href = href;
    }

    public async sso(idp) {
        if (idp.type === "oidc") {
            const body = { key: idp.key };
            if (this.token) body.token = this.token;
            const { code, data } = await wiz.call("login_uri", body);
            if (code !== 200) return await this.service.error("SERVER ERROR");
            return location.href = data;
        }
        else { // saml
            if (idp.federation) { // federation
                const body = this.service.copy(idp);
                if (this.token) body.token = this.token;
                const { code } = await wiz.call("discovery", body);
                if (code !== 200) return await this.service.error("SERVER ERROR");
                return location.href = `/modules/discovery`;
            }
            let params = {
                key: idp.key,
                authnContextRef: "urn:oasis:names:tc:SAML:2.0:ac:classes:Password",
            };
            if (idp.entityid) params.entityid = idp.entityid;
            if (this.token) params.token = this.token;
            params = new URLSearchParams(params);
            return location.href = `/sp/login?${params.toString()}`;
        }
    }

    public async join() {
        if (this.data.first_name.length < 2) return await this.alert("이름을 입력해주세요");
        if (this.data.last_name.length < 2) return await this.alert("성을 입력해주세요");

        let password = this.data.password;
        let password_re = this.data.password_repeat;
        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        if (!this.data.purpose) return await this.service.error("가입목적을 입력해주세요.");

        let user = this.service.copy(this.data);
        delete user.password_repeat;
        user.password = this.service.auth.hash(user.password);
        delete user.onetimepass;
        if (!user.username) return await this.service.error("ID를 입력해주세요.");

        let { code, data } = await wiz.call("join", user);

        if (code == 200) {
            await this.alert("회원가입 신청이 완료되었습니다.\n입력한 이메일로 24시간동안 유효한 인증 메일을 전송하였습니다.\n이메일 인증 및 관리자의 승인 후 로그인할 수 있습니다.", "success");
            location.reload();
        } else {
            await this.alert(data);
        }
    }

    // join
    public captcha = null;
    public async captchaRefresh() {
        this.captcha = null;
        await this.service.render(100);
        this.captcha = this.captchaURL();
        await this.service.render();
    }
    public captchaURL() {
        return wiz.url(`captcha?ts=${+new Date()}`);
    }

    // password reset
    public verifyKey = null;
    public async sendCode() {
        const email = this.data.email;
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return await this.service.error("이메일 형식이 아닙니다.");
        this.verifyKey = null;

        const body = {
            username: this.data.username,
            email,
        };
        await this.service.loading.show();
        const { code, data } = await wiz.call('send_code', body);
        await this.service.loading.hide();
        if (code !== 200) return await this.alert(data, "error");
        this.verifyKey = data;
        await this.alert('이메일 인증코드가 발송되었습니다.\n인증코드는 5분간 유효합니다.', 'success');
    }

    public async resetPassword() {
        const email = this.data.email;
        if (!email) return;
        const onetimepass = this.data.onetimepass;
        if (!onetimepass) return;
        if (!this.data.username) return;
        const body = {
            username: this.data.username,
            email,
            onetimepass,
            key: this.verifyKey,
        };
        await this.service.loading.show();
        const { code, data } = await wiz.call('verify_code', body);
        await this.service.loading.hide();
        if (code !== 200) return await this.alert(data, "error");
        await this.service.success("이메일로 임의의 값으로 생성한 패스워드가 발송되었습니다.\n로그인 후 반드시 패스워드를 변경해주세요.");
        return location.reload();
    }

    // idp-area
    public idptext = "";
    public filter(name) {
        return name.toLowerCase().includes(this.idptext.toLowerCase());
    }

    // etc
    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
}

export default PageAuthenticateComponent;