import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.admin');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-admin',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.nav.admin/view.scss */
aside.navbar {
  width: 288px;
}

.navbar-vertical {
  position: relative;
  height: 100%;
}

li.nav-item.active .nav-link {
  color: var(--kc-primary) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--kc-primary);
}
li.nav-item.active a.dropdown-item.active {
  font-family: "main-eb";
}
li.nav-item.active a.dropdown-item.active span {
  font-family: "main-eb";
}

a.dropdown-item {
  min-width: 240px !important;
}
a.dropdown-item.active {
  background-color: #EDF8FF !important;
  border-left: 3px solid var(--kc-primary);
  color: var(--kc-primary) !important;
}`],
})
export class ComponentNavAdminComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0;
    }

    public text = "";
    public filter() {
        const t = this.text.toLowerCase();
        const parent = document.querySelector("aside.navbar");
        const regex = new RegExp(t, 'gi');
        const handler = (el) => {
            const originalHTML = el.innerHTML.replace(/\<\/?mark\>/g, '');
            el.innerHTML = originalHTML;

            const itemText = el.textContent.toLowerCase();
            if (itemText.includes(t)) {
                if (el.classList.contains("nav-link-title")) {
                    el.parentElement.style.display = "";
                }
                else {
                    el.style.display = '';
                }
                if (t.length > 0)
                    el.innerHTML = el.innerHTML.replace(regex, match => `<mark>${match}</mark>`);
            } else {
                if (el.classList.contains("nav-link-title")) {
                    el.parentElement.style.display = "none";
                }
                else {
                    el.style.display = 'none';
                }
            }
            
        }
        Array.from(parent.querySelectorAll(".nav-link-title")).forEach(handler);
        Array.from(parent.querySelectorAll(".dropdown-item")).forEach(handler);
    }
}

export default ComponentNavAdminComponent;