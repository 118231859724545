import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.attributes.samlattr');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-attributes-samlattr',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.attributes.samlattr/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table {
  table-layout: fixed;
}
table .btn {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.border-gray {
  border: 1px solid gray;
}`],
})
export class PortalKeycloudAdminAttributesSamlattrComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public loaded: boolean = false;
    public defaults = [];
    public list: any = [];
    public text = "";

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async load() {
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("load");
        if (code != 200) return;
        const { attributes, defaults } = data;
        this.list = attributes;
        this.defaults = defaults;
        this.loaded = true;
        await this.service.render();
    }

    public filter(item) {
        const name = item.name.toLowerCase();
        const uri = item.uri.toLowerCase();
        const text = this.text.toLowerCase();
        if (name.includes(text)) return true;
        if (uri.includes(text)) return true;
        return false;
    }

    public modal = {
        mode: null,
        data: null,
    };

    public closeModal() {
        this.modal = {
            mode: null,
            data: null,
        };
        this.service.render();
    }

    public isDefault() {
        return this.modal.data.default;
    }

    public showModal(mode, data = { name: "", uri: "", description: "" }) {
        this.modal.mode = mode;
        this.modal.data = this.service.copy(data);
        this.service.render();
    }

    public async update() {
        if (this.isDefault()) return;
        const body = this.service.copy(this.modal.data);
        if (body.name.replace(/\s/g, "").length === 0) return await this.service.error("속성 이름을 반드시 입력해주세요.");
        if (body.uri.replace(/\s/g, "").length === 0) return await this.service.error("속성 URI를 반드시 입력해주세요.");
        if (!/^[A-Za-z0-9_\-]+$/.test(body.name)) return await this.service.error("속성 이름은 알파벳, 숫자, _, - 로 이루어져야 합니다.");
        if (this.list.filter(it => !it.default).map(it => it.name).includes(body.name)) return await this.service.error("속성의 이름이 중복되었습니다.");
        if (this.defaults.includes(body.name)) {
            const res = await this.service.alert.show({
                title: "기본 SAML Attribute 덮어쓰기",
                message: `기본 SAML Attribute 중 같은 이름의 속성이 존재합니다.\n해당 속성을 덮어씌우시겠습니까?`,
                action: "덮어쓰기",
                cancel: "cancel",
            });
            if (!res) return;
        }
        await this.service.loading.show();
        const { code, data } = await wiz.call("update", body);
        await this.service.loading.hide();
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("저장 실패!");
        await this.service.success("저장 성공!");
        this.closeModal();
        await this.load();
    }

    public async remove() {
        if (this.isDefault()) return;
        const { name, uri } = this.modal.data;
        const res = await this.service.alert.show({
            title: "SAML Attribute 삭제하기",
            message: `${name}(${uri}) 속성을 삭제하시겠습니까?`,
            action: "삭제하기",
            cancel: "cancel",
            status: "error",
            actionBtn: "error",
        });
        if (!res) return;
        const id = this.modal.data.id;
        const { code } = await wiz.call("remove", { id });
        if (code !== 200) return await this.service.error("삭제 실패!");
        await this.service.success("삭제 성공!");
        this.closeModal();
        await this.load();
    }
}

export default PortalKeycloudAdminAttributesSamlattrComponent;