import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.admin');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { HostListener } from '@angular/core';

@Component({
    selector: 'wiz-component-nav-admin',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/component.nav.admin/view.scss */
aside.navbar {
  width: 288px;
}

.navbar-vertical {
  position: relative;
  height: 100%;
}

li.nav-item.active .nav-link {
  color: var(--kc-primary) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--kc-primary);
}
li.nav-item.active a.dropdown-item.active {
  font-family: "main-eb";
}
li.nav-item.active a.dropdown-item.active span {
  font-family: "main-eb";
}

a.dropdown-item {
  min-width: 240px !important;
}
a.dropdown-item.active {
  background-color: #EDF8FF !important;
  border-left: 3px solid var(--kc-primary);
  color: var(--kc-primary) !important;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
  border-bottom: 2px solid lightgray;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class ComponentNavAdminComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.loadCount();
    }

    @HostListener('document:click')
    public clickout() {
        this.service.navbar.toggle(true);
    }

    public modules = [];
    public menus = [];
    public async load() {
        this.menus = [];
        this.modules = [];
        const { code, data } = await wiz.call("load_menu");
        if (code !== 200) return await this.service.render();
        const { modules, menus } = data;
        this.modules = modules;
        this.menus = menus;
        await this.service.render();
    }

    public count = 0;
    public async loadCount() {
        this.count = 0;
        const { code, data } = await wiz.call("request_count");
        if (code !== 200) return await this.service.render();
        this.count = data;
        await this.service.render();
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0;
    }

    public text = "";
    public filter() {
        const t = this.text.toLowerCase();
        const parent = document.querySelector("aside.navbar");
        const regex = new RegExp(t, 'gi');
        const handler = (el) => {
            const originalHTML = el.innerHTML.replace(/\<\/?mark\>/g, '');
            el.innerHTML = originalHTML;

            const itemText = el.textContent.toLowerCase();
            if (itemText.includes(t)) {
                if (el.classList.contains("nav-link-title")) {
                    el.parentElement.style.display = "";
                }
                else {
                    el.style.display = '';
                }
                if (t.length > 0)
                    el.innerHTML = el.innerHTML.replace(regex, match => `<mark>${match}</mark>`);
            } else {
                if (el.classList.contains("nav-link-title")) {
                    el.parentElement.style.display = "none";
                }
                else {
                    el.style.display = 'none';
                }
            }

        }
        Array.from(parent.querySelectorAll(".nav-link-title")).forEach(handler);
        Array.from(parent.querySelectorAll(".dropdown-item")).forEach(handler);
    }

    public modal = null;
    public modalText = "";
    public _menus = [];
    public showModal(act) {
        this.modalText = "";
        this._menus = this.service.copy(this.menus);
        this.modal = act;
        this.service.render();
    }

    public includes(name) {
        const t = this.modalText.toLowerCase();
        if (t.length === 0) return true;
        const n = name.toLowerCase();
        return n.includes(t);
    }

    public disabled(temp) {
        return this._menus.map(it => it.link.slice(13)).includes(temp);
    }

    public select(temp) {
        if (this.disabled(temp)) return;
        this._menus.push({ link: `/admin/custom${temp}`, name: `` });
        this.service.render();
    }

    public unselect(i) {
        this._menus.splice(i, 1);
        this.service.render();
    }

    public async save() {
        const body = { menus: this._menus };
        const { code } = await wiz.call("save_menu", body);
        if (code !== 200) return await this.service.error("메뉴 저장 실패!");
        await this.service.success("메뉴 저장 성공!");
        this.showModal(null);
        await this.load();
    }
}

export default ComponentNavAdminComponent;