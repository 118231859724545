import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.history.agreement');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-history-agreement',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.history.agreement/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PortalKeycloudHistoryAgreementComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");
        await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const dump = 20;
        const startpage = Math.floor((page - 1) / dump) * dump + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public async cancelAgreement(item) {
        const res = await this.service.alert.show({
            title: "이용약관 동의 취소",
            message: `정말 이용약관 동의를 취소하시겠습니까?\n취소할 시 이 서비스를 이용하실 수 없습니다.`,
            action: "취소하기",
            cancel: "cancel",
        });
        if (!res) return;
        const body = {
            id: item.id,
        };
        const { code } = await wiz.call("cancel_agreement", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("이용약관 동의 취소 성공!");
        location.href = "/auth/logout";
    }
}

export default PortalKeycloudHistoryAgreementComponent;