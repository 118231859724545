import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.modules.edit');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-modules-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.modules.edit/view.scss */
@charset "UTF-8";
.left-side {
  width: 220px;
}

.tree-viewer {
  overflow: auto;
}
.tree-viewer ul {
  list-style-type: none;
  padding-left: 10px;
}
.tree-viewer ul li {
  position: relative;
  margin-left: 10px;
}
.tree-viewer .tree-item {
  cursor: pointer;
}
.tree-viewer .tree-item .actions {
  display: none;
  color: #aaa;
}
.tree-viewer .tree-item .actions *:hover {
  color: #333;
}
.tree-viewer .tree-item:hover {
  background-color: #eee;
}
.tree-viewer .tree-item:hover .actions {
  display: block;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 400px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
  border-bottom: 2px solid lightgray;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.bg-light-secondary {
  background-color: #e0e0e0; /* light와 secondary 사이의 중간 톤 */
}

.custom-shadow {
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), 4px 4px 20px rgba(0, 0, 0, 0.05);
}`],
})
export class PortalKeycloudAdminModulesEditComponent implements OnInit {
    public O = Object;
    public namespace = "";
    public shortcuts: any = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.namespace = WizRoute.segment.namespace;
        await this.getRoot();
    }

    public current = null;
    public data = {};

    public monacoEditor: any;
    public monacoopts: any = {
        value: '',
        language: 'html',
        theme: "vs",
        fontSize: 14,
        automaticLayout: true,
        scrollBeyondLastLine: false,
        wordWrap: true,
        roundedSelection: false,
        glyphMargin: false,
        minimap: {
            enabled: false
        },
        renderValidationDecorations: 'off',
    }
    public monacoLoaded = false;
    public async init(event: any) {
        this.monacoLoaded = false;
        await this.service.render();
        let { editor } = event;
        this.monacoEditor = editor;
        await this.bindShortcuts();
        this.monacoLoaded = true;
        await this.service.render();
    }

    public saved = false;
    public async save() {
        if (!this.current) return;
        const path = this.current.path;
        const text = this.text;
        const body = {
            namespace: this.namespace,
            path,
            text,
        };
        const { code } = await wiz.call("save", body);
        if (code !== 200) return await this.service.error("Error");
        this.data[path] = text;
        this.saved = true;
        await this.service.render();
        setTimeout(() => {
            this.saved = false;
            this.service.render();
        }, 2000);
    }

    public async bindShortcuts() {
        this.shortcuts.push({
            key: ["cmd + s", "ctrl + s"],
            preventDefault: true,
            command: async () => {
                this.save();
            },
        });

        for (let i = 0; i < this.shortcuts.length; i++)
            this.shortcuts[i].allowIn = ['TEXTAREA', 'INPUT', 'SELECT'];

        await this.service.render();
    }

    public root = null;
    public text = "";
    public async getRoot() {
        const { code, data } = await wiz.call("root", { namespace: this.namespace });
        if (code !== 200) return await this.service.error("Error!");
        this.root = data;
        await this.service.render();
    }

    public async open(e, item) {
        e.preventDefault();
        e.stopPropagation();
        if (item.children) return;
        if (!this.data[item.path]) {
            const body = {
                namespace: this.namespace,
                path: item.path,
            };
            const { code, data } = await wiz.call("load", body);
            if (code !== 200) return await this.service.error("Error!");
            this.data[item.path] = data;
        }
        this.current = item;
        const text = this.data[item.path];
        const arr = item.path.split(".").slice(-1);
        let ext = "";
        if (arr.length > 0) ext = arr[0];
        let lang = "plaintext";
        const map = {
            html: "html",
            css: "css",
            js: "javascript",
            py: "python",
        };
        if (map[ext]) lang = map[ext];

        this.text = text;
        this.monacoopts.language = lang;
        if (ext === "html") this.monacoEditor.updateOptions({ renderValidationDecorations: 'off' });
        else this.monacoEditor.updateOptions({ renderValidationDecorations: 'on' });
        monaco.editor.setModelLanguage(this.monacoEditor.getModel(), lang);
        this.service.render();
    }

    public url() {
        if (!this.current) return `/modules/${this.namespace}`;

        let path = this.current.path;
        if (path.startsWith("templates")) {
            const len = "templates/".length;
            path = path.slice(len);
            if (path === "templates/index.html") return `/modules/${this.namespace}`;
            if (path.endsWith("/index.html")) return `/modules/${this.namespace}/${path.slice(0, "/index.html".length * -1)}`;

            return `/modules/${this.namespace}/${path}`;
        }

        return `/modules/${this.namespace}`;
    }

    public modal = {
        type: null,
        name: "",
    };
    public showModal(_type) {
        this.modal = {
            type: _type,
            name: "",
        };
        this.service.render();
    }
    public async createSample(type, name = undefined) {
        if (['process', 'templates'].includes(type) && name.length < 2) return;
        const body = {
            namespace: this.namespace,
            type,
            name,
        };
        const { code } = await wiz.call("sample", body);
        if (code !== 200) return await this.service.error("Error!");
        this.showModal(null);
        this.getRoot();
        this.service.success("샘플 코드 생성 성공!");
    }

    public async download() {
        const url = wiz.url("download");
        window.open(`${url}?namespace=${this.namespace}`);
    }

    public async remove(path) {
        const res = await this.service.alert.show({
            title: `파일/폴더 삭제`,
            status: "error",
            message: `정말 "${path}"를 삭제하시겠습니까?`,
            action: "삭제",
            actionBtn: "error",
            cancel: "cancel",
        });
        if (!res) return;
        const body = {
            namespace: this.namespace,
            path,
        };
        const { code } = await wiz.call("remove", body);
        if (code !== 200) return await this.service.error("Error!");
        this.getRoot();
    }
}

export default PortalKeycloudAdminModulesEditComponent;