import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.modules');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-modules',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.modules/view.scss */
/* src/app/page.admin.modules/view.scss: no such file or directory */`],
})
export class PageAdminModulesComponent implements OnInit, DoCheck {
    public ID = null;
    constructor(@Inject( Service)         public service: Service,    ) {
        this.ID = WizRoute.segment.namespace;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/mypage");
    }

    public async ngDoCheck() {
        let preID = this.ID;
        this.ID = WizRoute.segment.namespace;
        if (preID != this.ID)
            await this.service.render();
    }
}

export default PageAdminModulesComponent;