import '@angular/compiler';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalKeycloudExternalRpCredentialsComponent } from './portal.keycloud.external.rp.credentials/portal.keycloud.external.rp.credentials.component';
import { PortalKeycloudExternalSpComponent } from './portal.keycloud.external.sp/portal.keycloud.external.sp.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageAdminSessionComponent } from './page.admin.session/page.admin.session.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { PageAdminAgreementComponent } from './page.admin.agreement/page.admin.agreement.component';
import { PageAdminUsersRequestComponent } from './page.admin.users.request/page.admin.users.request.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalKeycloudExternalRpLogComponent } from './portal.keycloud.external.rp.log/portal.keycloud.external.rp.log.component';
import { PageAdminCustomComponent } from './page.admin.custom/page.admin.custom.component';
import { PageRegisterComponent } from './page.register/page.register.component';
import { PageAdminModulesprocComponent } from './page.admin.modulesproc/page.admin.modulesproc.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PageHistoryComponent } from './page.history/page.history.component';
import { PortalKeycloudAdminInternalOidcComponent } from './portal.keycloud.admin.internal.oidc/portal.keycloud.admin.internal.oidc.component';
import { ComponentLangComponent } from './component.lang/component.lang.component';
import { PageAdminExternalItemComponent } from './page.admin.external.item/page.admin.external.item.component';
import { PageMgmtComponent } from './page.mgmt/page.mgmt.component';
import { PortalKeycloudExternalRpTokenComponent } from './portal.keycloud.external.rp.token/portal.keycloud.external.rp.token.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PortalKeycloudAdminAttributesListComponent } from './portal.keycloud.admin.attributes.list/portal.keycloud.admin.attributes.list.component';
import { PortalKeycloudAdminModulesEditComponent } from './portal.keycloud.admin.modules.edit/portal.keycloud.admin.modules.edit.component';
import { PageAdminModulesComponent } from './page.admin.modules/page.admin.modules.component';
import { PageAdminDiscoveryComponent } from './page.admin.discovery/page.admin.discovery.component';
import { PageAdminInternalComponent } from './page.admin.internal/page.admin.internal.component';
import { PortalKeycloudExternalIdpComponent } from './portal.keycloud.external.idp/portal.keycloud.external.idp.component';
import { ComponentQuestionComponent } from './component.question/component.question.component';
import { PageDeviceComponent } from './page.device/page.device.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PageAgreementComponent } from './page.agreement/page.agreement.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalKeycloudExternalOpInfoComponent } from './portal.keycloud.external.op.info/portal.keycloud.external.op.info.component';
import { PageAdminUsersComponent } from './page.admin.users/page.admin.users.component';
import { PortalKeycloudHistoryAgreementComponent } from './portal.keycloud.history.agreement/portal.keycloud.history.agreement.component';
import { PageLinkPasswordComponent } from './page.link.password/page.link.password.component';
import { PageAdminLogPageComponent } from './page.admin.log.page/page.admin.log.page.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalKeycloudAdminInternalSamlComponent } from './portal.keycloud.admin.internal.saml/portal.keycloud.admin.internal.saml.component';
import { ComponentDotBlinkComponent } from './component.dot.blink/component.dot.blink.component';
import { PortalKeycloudExternalAttributemapComponent } from './portal.keycloud.external.attributemap/portal.keycloud.external.attributemap.component';
import { PageMgmtItemComponent } from './page.mgmt.item/page.mgmt.item.component';
import { PortalKeycloudHistoryConsentComponent } from './portal.keycloud.history.consent/portal.keycloud.history.consent.component';
import { PortalKeycloudAdminDiscoveryComponent } from './portal.keycloud.admin.discovery/portal.keycloud.admin.discovery.component';
import { PortalKeycloudAdminAttributesScopesComponent } from './portal.keycloud.admin.attributes.scopes/portal.keycloud.admin.attributes.scopes.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageAdminAttributesComponent } from './page.admin.attributes/page.admin.attributes.component';
import { PageAdminExternalComponent } from './page.admin.external/page.admin.external.component';
import { PortalKeycloudExternalRpOwnersComponent } from './portal.keycloud.external.rp.owners/portal.keycloud.external.rp.owners.component';
import { PortalKeycloudExternalSamlIdpFilterComponent } from './portal.keycloud.external.saml.idp.filter/portal.keycloud.external.saml.idp.filter.component';
import { ComponentPreComponent } from './component.pre/component.pre.component';
import { ComponentNavComponent } from './component.nav/component.nav.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalKeycloudExternalRpScopesComponent } from './portal.keycloud.external.rp.scopes/portal.keycloud.external.rp.scopes.component';
import { PortalKeycloudExternalRpInfoComponent } from './portal.keycloud.external.rp.info/portal.keycloud.external.rp.info.component';
import { PortalKeycloudAdminModulesListComponent } from './portal.keycloud.admin.modules.list/portal.keycloud.admin.modules.list.component';
import { PortalKeycloudAdminAttributesSamlattrComponent } from './portal.keycloud.admin.attributes.samlattr/portal.keycloud.admin.attributes.samlattr.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';

const INDEX_PAGE = "authenticate";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutAdminComponent,
        "children": [
            {
                "path": "admin/session",
                component: PageAdminSessionComponent,
                "app_id": "page.admin.session"
            },
            {
                "path": "admin/agreement",
                component: PageAdminAgreementComponent,
                "app_id": "page.admin.agreement"
            },
            {
                "path": "admin/users/request",
                component: PageAdminUsersRequestComponent,
                "app_id": "page.admin.users.request"
            },
            {
                "path": "admin/custom/**",
                component: PageAdminCustomComponent,
                "app_id": "page.admin.custom"
            },
            {
                "path": "admin/modulesproc",
                component: PageAdminModulesprocComponent,
                "app_id": "page.admin.modulesproc"
            },
            {
                "path": "admin/external/:type/:category/:id/:menu?",
                component: PageAdminExternalItemComponent,
                "app_id": "page.admin.external.item"
            },
            {
                "path": "admin/modules/:namespace?",
                component: PageAdminModulesComponent,
                "app_id": "page.admin.modules"
            },
            {
                "path": "admin/discovery/:type",
                component: PageAdminDiscoveryComponent,
                "app_id": "page.admin.discovery"
            },
            {
                "path": "admin/internal/:type",
                component: PageAdminInternalComponent,
                "app_id": "page.admin.internal"
            },
            {
                "path": "admin/users/list",
                component: PageAdminUsersComponent,
                "app_id": "page.admin.users"
            },
            {
                "path": "admin/log/:type",
                component: PageAdminLogPageComponent,
                "app_id": "page.admin.log.page"
            },
            {
                "path": "admin/attributes/:type",
                component: PageAdminAttributesComponent,
                "app_id": "page.admin.attributes"
            },
            {
                "path": "admin/external/:type?/:category?",
                component: PageAdminExternalComponent,
                "app_id": "page.admin.external"
            }
        ]
    },
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "register",
                component: PageRegisterComponent,
                "app_id": "page.register"
            },
            {
                "path": "device",
                component: PageDeviceComponent,
                "app_id": "page.device"
            },
            {
                "path": "agreement",
                component: PageAgreementComponent,
                "app_id": "page.agreement"
            },
            {
                "path": "link/password",
                component: PageLinkPasswordComponent,
                "app_id": "page.link.password"
            },
            {
                "path": "authenticate",
                component: PageAuthenticateComponent,
                "app_id": "page.authenticate"
            }
        ]
    },
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "history/:type",
                component: PageHistoryComponent,
                "app_id": "page.history"
            },
            {
                "path": "mgmt/:type?/:category?",
                component: PageMgmtComponent,
                "app_id": "page.mgmt"
            },
            {
                "path": "mypage",
                component: PageMypageComponent,
                "app_id": "page.mypage"
            },
            {
                "path": "mgmt/:type/:category/:id/:menu?",
                component: PageMgmtItemComponent,
                "app_id": "page.mgmt.item"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }