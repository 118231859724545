import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.attributemap');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-attributemap',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.attributemap/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table {
  table-layout: fixed;
}
table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.btn.btn-sm {
  width: auto !important;
  height: 24px !important;
  padding: 0 6px !important;
}

.nowrap {
  white-space: nowrap;
}`],
})
export class PortalKeycloudExternalAttributemapComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() category: any;
    @Input() info: any;

    public attributes = [];
    public list: any = [];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        if (['idp', 'sp'].includes(this.category)) {
            await this.getSAMLAttributes();
        }
        await this.load();
    }

    public placeholder() {
        if (this.info.type === "saml") return "urn:oid:0.9.2342.19200300.100.1.3";
        else return "email";
    }

    public samlAttributes = [];
    public async getSAMLAttributes() {
        this.samlAttributes = [];
        const { code, data } = await wiz.call("saml_attributes");
        if (code === 200) {
            this.samlAttributes = data.map(it => {
                return {
                    value: it.name,
                    label: `${it.name} (${it.uri})`,
                };
            });
        }
        await this.service.render();
    }

    public async load() {
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("load", { key: this.info.key });
        if (code !== 200) return;

        const { attributes, attrmap } = data;
        this.attributes = attributes.map(attr => ({ label: attr, value: attr }));
        this.list = attrmap;
        await this.service.render();
        setTimeout(() => {
            this.service.render();
        }, 0);
    }

    public onSelect(item, key, { value }) {
        if (!value) return;
        item[key] = value;
        this.service.render();
    }

    public add() {
        this.list.push({ required: false, key: "", attribute: null });
        this.service.render();
    }

    public remove(i) {
        this.list.splice(i, 1);
        this.service.render();
    }

    public async update() {
        const body = {
            key: this.info.key,
            attrmaps: this.list,
        };
        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }
}

export default PortalKeycloudExternalAttributemapComponent;