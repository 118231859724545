import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mgmt.item');
import { OnInit, DoCheck } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mgmt-item',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.mgmt.item/view.scss */
.btn-group {
  box-shadow: none;
}
.btn-group .btn {
  min-width: 120px;
  height: 36px;
}

.col,
.col-auto {
  align-items: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.main-container .container {
  border-right: 1px solid var(--wc-border);
}
.main-container .header {
  border-bottom: 1px solid var(--wc-border);
}
.main-container .context {
  flex: 1;
  overflow: auto;
}

.container {
  max-width: 100%;
}

.menu-container {
  margin: 0 auto;
}`],
})
export class PageMgmtItemComponent implements OnInit, DoCheck {
    public TYPE: string = '';
    public CATEGORY: string = '';
    public ID: string = '';
    public MENU: string = '';

    public loaded: boolean = false;
    public info: any = {};

    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        this.CATEGORY = WizRoute.segment.category ? WizRoute.segment.category : '';
        this.ID = WizRoute.segment.id ? WizRoute.segment.id : '';
        if (!this.ID) return service.href(`/mgmt/${this.TYPE}/${this.CATEGORY}`);
        this.MENU = WizRoute.segment.menu ? WizRoute.segment.menu : '';
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
        await this.load();
    }

    public async ngDoCheck() {
        let preID = this.ID;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        this.CATEGORY = WizRoute.segment.category ? WizRoute.segment.category : '';
        this.ID = WizRoute.segment.id ? WizRoute.segment.id : '';
        this.MENU = WizRoute.segment.menu ? WizRoute.segment.menu : '';
        if (!this.ID) return this.service.href(`/mgmt/${this.TYPE}/${this.CATEGORY}`);
        if (preID != this.ID)
            await this.load();
    }

    public title() {
        if (this.ID === "new") {
            return "New " + this.service.lang.trans(`title.external.${this.TYPE}.${this.CATEGORY}`);
        }
        else {
            return this.info.name;
        }
    }

    public subtitle() {
        const type = this.TYPE.toUpperCase();
        const text = this.service.lang.trans(`title.external.${this.TYPE}.${this.CATEGORY}`);
        if (this.ID === "new") {
            return `New ${type} ${text}`;
        }
        else {
            return `${text} ${this.MENU}`;
        }

    }

    public async load() {
        this.info = {};
        if (this.ID === "new") {
            this.loaded = true;
            return await this.service.render();
        }
        this.loaded = false;
        await this.service.render();

        const { code, data } = await wiz.call("info", {
            type: this.TYPE,
            category: this.CATEGORY,
            id: this.ID,
        });
        if (code !== 200) {
            return this.service.href(`/mgmt/${this.TYPE}/${this.CATEGORY}`);
        }

        this.info = data;

        this.loaded = true;
        await this.service.render();
    }

    public is(tab: string) {
        return this.MENU == tab;
    }

    public isnot(tab: string) {
        return this.MENU != tab;
    }

    public async active(tab: string) {
        this.service.href(`/mgmt/${this.TYPE}/${this.CATEGORY}/${this.ID}/${tab}`);
    }

    public async fetchMetadata() {
        if (!this.info.src.extra.federation_url) return;
        const body = { id: this.info.src.id, url: this.info.src.extra.federation_url };
        const { code } = await wiz.call("metadata_refresh", body);
        if (code !== 200) return await this.service.error("ERROR");
        await this.service.success("Metadata refreshed");
        await this.load();
    }
}

export default PageMgmtItemComponent;