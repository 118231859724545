import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.config');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-config',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.config/view.scss */
/* src/app/page.admin.config/view.scss: no such file or directory */`],
})
export class PageAdminConfigComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/admin/config/system");
        if (!['system', 'consent'].includes(this.TYPE))
            return service.href("/admin/config/system");
    }

    public TYPE: string = '';

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
    }

    public async ngDoCheck() {
        let preType = this.TYPE;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return this.service.href("/admin/config/system");
        if (preType != this.TYPE)
            await this.load();
    }
}

export default PageAdminConfigComponent;