import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.rp.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-rp-info',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.rp.info/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.align-items-normal {
  align-items: normal;
}

.new-scope-input {
  width: 150px;
  vertical-align: middle;
  display: inline-block;
}

.mr-minus {
  margin-right: -5px;
}

.new-claim-selet {
  width: 120px;
  display: inline-block;
}`],
})
export class PortalKeycloudExternalRpInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any = {};

    public loaded: boolean = false;

    public grant_types = [
        "authorization_code",
        "client_credentials",
        "implicit",
        "password",
        "refresh_token",
        "urn:ietf:params:oauth:grant-type:token-exchange",
        "urn:ietf:params:oauth:grant-type:device_code",
    ];

    public response_types = {
        code: true,
        token: true,
        id_token: true,
    };

    public unlimit = {
        access_token: false,
        id_token: false,
        refresh_token: false,
    };

    public redirect_uri: any = "";

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.extra) this.info.extra = {};
        if (!this.info.src) this.info.src = {};
        if (this.info.src.id) {
            await this.loadTenants();

            if (this.info.src.response_types.includes("code")) this.response_types.code = true;
            else this.response_types.code = false;
            if (this.info.src.response_types.includes("token")) this.response_types.token = true;
            else this.response_types.token = false;
            if (this.info.src.response_types.includes("id_token")) this.response_types.id_token = true;
            else this.response_types.id_token = false;

            if (this.info.src.token_usage_rules.access_token.expires_in === -1) this.unlimit.access_token = true;
            if (this.info.src.token_usage_rules.id_token.expires_in === -1) this.unlimit.id_token = true;
            if (this.info.src.token_usage_rules.refresh_token.expires_in === -1) this.unlimit.refresh_token = true;
        }
        this.loaded = true;
        await this.service.render();
    }

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }

    public tenantList = [];

    public async loadTenants() {
        const { code, data } = await wiz.call("tenants");
        if (code !== 200) return await this.service.error("Error");
        this.tenantList = data;
        if (this.info.extra.tenants) {
            this.tenantList.forEach(tenant => {
                const tenants = this.info.extra.tenants;
                const tmp = tenants.map(it => {
                    if (typeof it === 'string') return it;
                    else return it.id;
                });
                if (tmp.includes(tenant.id)) tenant.checked = true;
                if (tenant.checked) {
                    for (const t of this.info.extra.tenants) {
                        if (typeof t === "string") {
                            if (t !== tenant.id) continue;
                            tenant.providers.forEach(it => { it.checked = true; });
                        }
                        else {
                            if (t.id !== tenant.id) continue;
                            tenant.providers.forEach(it => {
                                if (t.ids.includes(it.id)) it.checked = true;
                            });
                        }
                    }
                }
            });
        }
        await this.service.render();
    }

    public tenantCurrent = null;
    public toggleTenantDetail(tenant) {
        if (this.tenantCurrent === null) this.tenantCurrent = tenant;
        else {
            if (this.tenantCurrent.id === tenant.id) this.tenantCurrent = null;
            else this.tenantCurrent = tenant;
        }
        this.service.render();
    }
    public selectAllTenantCurrent() {
        const arr = this.tenantCurrent.providers;
        let checked = true;
        if (arr.filter(it => it.checked).length === arr.length) {
            checked = false;
        }
        arr.forEach(it => {
            it.checked = checked;
        });
        this.service.render();
    }

    public async addRedirectUri() {
        if (!this.redirect_uri) return;
        if (!this.info.src.redirect_uris) this.info.src.redirect_uris = [];
        this.info.src.redirect_uris.push(this.redirect_uri);
        this.redirect_uri = "";
        await this.service.render();
    }

    public async update() {
        const body = this.service.copy(this.info);
        const tenants = this.tenantList.reduce((acc, tenant) => {
            if (!tenant.checked) return acc;
            const arr = tenant.providers;
            const filtered = arr.filter(it => it.checked);
            // 전체 체크 : tenant id만
            if (arr.length === filtered.length) {
                acc.push(tenant.id);
            }
            // 일부 체크 : type, provider ids
            else {
                acc.push({ id: tenant.id, type: tenant.type, ids: filtered.map(it => it.id) })
            }
            return acc;
        }, []);
        body.extra.tenants = tenants;
        delete body.owner;
        delete body.constructor;

        body.src.response_types = this.getResponseTypes();

        const exclude = "client_secret,client_salt,allowed_scopes,add_claims,scopes_to_claims".split(",");
        exclude.forEach(key => {
            delete body.src[key];
        });
        if (!this.info.id && this.redirect_uri.length > 0) {
            await this.addRedirectUri();
        }

        const { code, data } = await wiz.call("update", body);
        if (code === 403) return await this.service.error(data);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        if (!this.info.id)
            return location.href = this._url(`/external/oidc/rp/${data}/info`);
        await this.service.success("Success to save");
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete OIDC RP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", { data: JSON.stringify(this.info) });
        this.back();
    }

    public back() {
        this.service.href(this._url("/external/oidc/rp"));
    }

    public onSelectGrantTypes(grant_type) {
        const idx = this.info.src.grant_types_supported.findIndex(it => it === grant_type);
        if (idx >= 0) this.info.src.grant_types_supported.splice(idx, 1);
        else this.info.src.grant_types_supported.push(grant_type);
        this.service.render();
    }

    // ["code","token","id_token","code token","code id_token","id_token token","code id_token token","none"]
    public getResponseTypes() {
        const res = [];
        const arr = this.response_types;
        if (arr.code) res.push("code");
        if (arr.token) res.push("token");
        if (arr.id_token) res.push("id_token");
        if (arr.code && arr.token) res.push("code token");
        if (arr.code && arr.id_token) res.push("code id_token");
        if (arr.id_token && arr.token) res.push("id_token token");
        if (arr.code && arr.id_token && arr.token) res.push("code id_token token");
        if (res.length === 0) res.push("none");
        return res;
    }

    public check(key) {
        if (this.unlimit[key]) this.info.src.token_usage_rules[key].expires_in = -1;
        else this.info.src.token_usage_rules[key].expires_in = 3600;
        this.service.render();
    }
}

export default PortalKeycloudExternalRpInfoComponent;