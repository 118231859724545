import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.agreement');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ClassicEditor from "src/libs/portal/season/ckeditor/ckeditor";

@Component({
    selector: 'wiz-page-admin-agreement',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.agreement/view.scss */
.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  min-width: 100px;
  flex-grow: 1 !important;
}
.wiz-modal .modal-content .badge {
  position: relative;
}
.wiz-modal .modal-content .badge .btn-close {
  position: inherit;
  padding: 0 12px !important;
}
.wiz-modal .modal-content .badge .btn-close:hover {
  background-color: inherit;
}

@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.w-35 {
  width: 35% !important;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-left {
  justify-self: left;
}

.align-center {
  align-content: center;
}

.btn.btn-season {
  height: 32px;
}`],
})
export class PageAdminAgreementComponent implements OnInit {
    public list = [];
    public showModal = false;
    public current = null;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/mypage");
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) {
            return await this.service.error("Error");
        }
        this.list = data;
        await this.service.render();
    }

    public async use({ id }) {
        const { code } = await wiz.call("use", { id });
        if (code !== 200) {
            return await this.service.error("Error");
        }
        await this.load();
    }

    public async toggleModal(data) {
        if (data === null) {
            this.showModal = false;
            this.current = null;
            return this.service.render();
        }
        if (data === true) {
            this.current = {
                version: "",
                user_agreement: "",
                privacy_policy: "",
                use: false,
            };
        }
        else this.current = this.service.copy(data);
        this.showModal = true;
        await this.service.render();
        await this.init();
        this.service.render();
    }

    public onlyText(text) {
        const div = document.createElement("div");
        div.innerHTML = text;
        return div.textContent;
    }

    public tab = "user_agreement";
    public editor1: any;
    public editor2: any;
    public isNew = false;

    public async init() {
        const opt = {
            toolbar: {
                items: 'heading | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | insertTable | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true,
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
        };
        this.editor1 = await ClassicEditor.create(document.querySelector("textarea#text1"), opt);
        this.editor2 = await ClassicEditor.create(document.querySelector("textarea#text2"), opt);

        if (this.current.user_agreement) this.editor1.data.set(this.current.user_agreement);
        if (this.current.privacy_policy) this.editor2.data.set(this.current.privacy_policy);
        await this.service.render();
    }

    public async changeTab(tab) {
        this.tab = tab;
        await this.service.render();
    }

    public async save() {
        if (this.current.version.replace(/\s/g, "").length < 1) return await this.service.error(`버전을 반드시 명시해주세요.`);
        const body = {
            ...this.current,
            user_agreement: this.editor1.data.get(),
            privacy_policy: this.editor2.data.get(),
        };
        if (body.id && body.use === true) {
            const res = await this.service.alert.show({
                title: 'Confirm',
                message: '수정 시 사용자들에게 동의를 다시 받아야 합니다. 정말 진행하시겠습니까?',
                cancel: `cancel`,
                actionBtn: 'error',
                action: 'save',
                status: 'error'
            });
            if (!res) return;
        }
        const { code } = await wiz.call("save", body);
        if (code !== 200) return await this.service.error(`Error`);
        await this.service.success("저장 성공");
        await this.load();
        this.toggleModal(null);
    }
}

export default PageAdminAgreementComponent;