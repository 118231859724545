import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.modulesproc');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-modulesproc',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.modulesproc/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

.modules-list {
  min-width: 250px;
  max-width: 500px;
}
.modules-list > div:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.process {
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  box-shadow: var(--tblr-btn-box-shadow);
  border: 1px solid #dbdfe5;
}`],
})
export class PageAdminModulesprocComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/mypage");
        await this.load();
    }

    public text = "";
    public list = [];
    public proc = [];
    public listOpt = {
        group: {
            name: 'shared',
            pull: 'clone',
            put: false // Do not allow items to be put into this list
        },
        animation: 150,
        sort: false, // To disable sorting: set sort to false
    };
    public procOpt = {
        group: 'shared',
        handle: '.handle',
        animation: 150,
    };

    public async load() {
        this.list = [];
        this.proc = [];
        await this.service.render();
        const { code, data } = await wiz.call("load");
        if (code !== 200) return;
        const { modules, process } = data;
        this.list = modules;
        this.proc = process;
        await this.service.render();
    }

    public async save() {
        const body = this.service.copy(this.proc);
        const { code } = await wiz.call("save", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("Saved!");
    }

    public includes(item) {
        const t = this.text.toLowerCase();
        if (item.toLowerCase().includes(t)) return true;
        return false;
    }
}

export default PageAdminModulesprocComponent;