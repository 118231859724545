import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.log.page');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-log-page',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.log.page/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table .btn {
  width: 32px;
  height: 32px;
}
table thead th {
  background-color: var(--wc-light-2);
}
table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}`],
})
export class PageAdminLogPageComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) {
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return service.href("/admin/log/access");
        if (!['access', 'admin', 'request', 'agreement'].includes(this.TYPE))
            return service.href("/admin/log/access");
    }

    public TYPE: string = '';
    public headers() {
        if (this.TYPE === 'access') return ["action", "user", "sp", "idp"];
        if (this.TYPE === 'admin') return ["action", "ref", "user", "action_by", "msg"];
        if (this.TYPE === 'request') return ["action", "user", "request", "msg"];
        if (this.TYPE === 'agreement') return ["user", "agreement"];
        return [];
    }

    public loaded: boolean = false;
    public list: any = [];
    public search: any = {
        page: 1,
        text: ''
    };
    public pagenation: any = {
        end: 1,
        start: 1
    };

    public title() {
        try {
            return this.service.lang.trans(this.TYPE) + " Log";
        } catch {
            return "";
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        await this.load();
    }

    public async ngDoCheck() {
        let preType = this.TYPE;
        this.TYPE = WizRoute.segment.type ? WizRoute.segment.type : '';
        if (!this.TYPE)
            return this.service.href("/admin/log/access");
        if (preType != this.TYPE)
            await this.load();
    }

    public async load(page: number = 1) {
        this.search.page = page;
        this.search.type = this.TYPE;
        if (!this.search.type) return;
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const dump = 20;
        const startpage = Math.floor((page - 1) / dump) * dump + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public display = {
        action: v => v,
        user: this.displayUser,
        action_by: this.displayUser,
        sp: this.displayRef,
        idp: this.displayRef,
        ref: this.displayRef,
        agreement: v => v.version,
        request: v => v.type,
        msg: v => v,
    };

    public displayUser(user) {
        if (!user) return "-";
        return `${user.first_name} ${user.last_name} (${user.email})`;
    }

    public displayRef(item) {
        if (!item) return "-";
        return `[${item.type}] ${item.name}`;
    }

}

export default PageAdminLogPageComponent;