import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.admin.internal.saml');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-admin-internal-saml',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.admin.internal.saml/view.scss */
.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.form-check {
  height: 44px;
  line-height: 44px;
  align-content: center;
  cursor: pointer;
}
.form-check.check-type {
  color: var(--kc-t3);
  border: 1px solid #BFC2C3;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 15px;
}
.form-check.check-type.active {
  color: var(--tblr-primary);
  border-color: var(--tblr-primary);
  background-color: #EDF8FF;
}
.form-check.check-type input[type=checkbox] {
  margin: auto 0;
}

.form-check-radio-group {
  width: fit-content;
  border: 1px solid var(--tblr-primary);
  border-radius: 44px;
  color: var(--kc-t2);
}
.form-check-radio-group .form-check {
  border-radius: 44px;
  width: 185px;
  text-align: center;
}
.form-check-radio-group .form-check.active {
  color: white;
  font-family: main-b;
  background-color: var(--tblr-primary);
}
.form-check-radio-group .form-check .form-check-label {
  font-size: 1rem;
}

wiz-component-pre {
  line-height: normal;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}`],
})
export class PortalKeycloudAdminInternalSamlComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,    ) { }

    public tab = "idp";
    public menus = ["idp", "sp"];
    public menuMap = {
        idp: "IdP 모듈 설정",
        sp: "SP 모듈 설정",
    };
    public show(tab) {
        this.tab = tab;
        try {
            const currentUrl = new URL(`${location.origin}${location.pathname}`);
            currentUrl.searchParams.set("tab", tab);
            history.replaceState({}, "", currentUrl);
        } catch { }
        this.service.render();
    }

    public cert = {
        idp: "",
        sp: "",
    };

    public nameIDFormats = [
        "urn:oasis:names:tc:SAML:2.0:nameid-format:persistent",
        "urn:oasis:names:tc:SAML:2.0:nameid-format:transient",
        "urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified",
        "urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress",
    ];

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/mypage");
        const params = new URLSearchParams(location.search);
        if (this.menus.includes(params.get('tab'))) this.show(params.get('tab'));
        await this.getAttributes();
        await this.load();
        this.cert.idp = await this.getCert("idp");
        this.cert.sp = await this.getCert("sp");
        await this.service.render();
    }

    public origin() {
        return location.origin;
    }

    public attrMap = {};
    public attributes = [];
    public async getAttributes() {
        const { code, data } = await wiz.call("attributes");
        if (code !== 200) this.attributes = [];
        this.attributes = data;
        this.attrMap = this.attributes.reduce((acc, { name, uri }) => {
            acc[name] = uri;
            return acc;
        }, {});
        await this.service.render();
    }
    public onSelect(attrname) {
        const _type = this.modal;
        const arr = this.sp[`${_type}_attributes`].split(",");
        arr.push(attrname);
        this.sp[`${_type}_attributes`] = arr.filter(it => it).join(",");
        this.showModal(null);
    }
    public remove(key, attr) {
        const arr = this.sp[`${key}_attributes`].split(",");
        const i = arr.findIndex(it => it === attr);
        if (i < 0) return;
        arr.splice(i, 1);
        this.sp[`${key}_attributes`] = arr.join(",");
        this.service.render();
    }
    public list(attrs) {
        return attrs.split(",").filter(it => it);
    }
    public includes(key) {
        const arr1 = this.sp.required_attributes.split(",");
        if (arr1.includes(key)) return true;
        const arr2 = this.sp.optional_attributes.split(",");
        if (arr2.includes(key)) return true;
        return false;
    }

    public idp = {
        entityid: "",
        name: "",
        org_name: "",
        org_displayname: "",
        org_url: "",
        contact_name: "",
        contact_email: "",
    };

    public sp = {
        entityid: "",
        name: "",
        description: "",
        required_attributes: "",
        optional_attributes: "",
        org_name: "",
        org_displayname: "",
        org_url: "",
        name_id_format: [],
        contact_name: "",
        contact_email: "",
    };

    public async getCert(type) {
        const { code, data } = await wiz.call("get_cert", { type });
        if (code !== 200) return "";
        return data;
    }

    public async load() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        Object.entries(data).forEach(([key, value]) => {
            if (key.startsWith("IDP_")) {
                const k = key.slice(4).toLowerCase();
                this.idp[k] = value;
            }
            else if (key.startsWith("SP_")) {
                const k = key.slice(3).toLowerCase();
                if (k === "name_id_format") this.sp[k] = value.split(",");
                else this.sp[k] = value;
            }
        });
        await this.service.render();
    }

    public changeNameID(nameID) {
        if (this.sp.name_id_format.includes(nameID)) {
            const i = this.sp.name_id_format.findIndex(it => it === nameID);
            if (i >= 0) this.sp.name_id_format.splice(i, 1);
        }
        else this.sp.name_id_format.push(nameID);
        this.service.render();
    }

    public async save() {
        const body = [];
        Object.entries(this.idp).forEach(([key, value]) => {
            body.push([`IDP_${key.toUpperCase()}`, value]);
        });
        Object.entries(this.sp).forEach(([key, value]) => {
            if (key === "name_id_format") {
                const v = value.join(",");
                body.push([`SP_${key.toUpperCase()}`, v]);
            }
            else body.push([`SP_${key.toUpperCase()}`, value]);
        });
        await this.service.loading.show();
        const { code } = await wiz.call("save", body);
        await this.service.loading.hide();
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }

    public modal = null;
    public modalText = "";
    public filteredAttributes() {
        if (this.modalText.length === 0) return this.attributes;
        return this.attributes.filter(it => {
            const terms = this.modalText.toLowerCase().trim().split(/\s+/);
            if (terms.every(txt => it.name.toLowerCase().includes(txt))) return true;
            if (terms.every(txt => it.uri.toLowerCase().includes(txt))) return true;
            return false;
        });
    }
    public showModal(act) {
        this.modal = act;
        this.modalText = "";
        this.service.render();
    }
}

export default PortalKeycloudAdminInternalSamlComponent;