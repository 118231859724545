import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.custom');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-custom',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.custom/view.scss */
/* src/app/page.admin.custom/view.scss: no such file or directory */`],
})
export class PageAdminCustomComponent implements OnInit, DoCheck {
    public src = null;

    constructor(@Inject( Service) public service: Service) {
        let src = WizRoute.segment[0] ? WizRoute.segment[0] : null;
        if (!src)
            return service.href("/admin/users/list");
        if (!src.startsWith("/")) src = `/${src}`;
        this.src = src;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow("admin", "/");
        document.querySelector("wiz-page-admin-custom").classList.add("h-100");
        document.querySelector("iframe").src = this.src;
    }

    public async ngDoCheck() {
        let presrc = this.src;
        let src = WizRoute.segment[0] ? WizRoute.segment[0] : null;
        if (!src)
            return this.service.href("/admin/users/list");
        if (!src.startsWith("/")) src = `/${src}`;
        this.src = src;
        if (presrc != src) {
            document.querySelector("iframe").src = src;
            await this.service.render();
        }
    }
}

export default PageAdminCustomComponent;