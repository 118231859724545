import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.keycloud.external.op.info');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-keycloud-external-op-info',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/portal.keycloud.external.op.info/view.scss */
.editor {
  height: 300px;
  border-left: 1px solid var(--wiz-color-border);
}

.row {
  margin: 0;
}

.col-header {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  margin: 0;
  background: var(--wc-light-2);
}

.card {
  border-radius: 0;
}

.col-value {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--wc-border);
  padding: 12px 16px;
  margin: 0;
}

.btn {
  height: 36px;
}
.btn.btn-sm {
  height: 18px;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.justify-item-start {
  justify-items: start;
}

.display-contents {
  display: contents;
}

.align-center {
  align-content: center;
}`],
})
export class PortalKeycloudExternalOpInfoComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    @Input() isAdmin = false;
    @Input() info: any;

    public loaded: boolean = false;

    public async ngOnInit() {
        await this.service.init();
        if (!this.info.src) this.info.src = {};
        if (!this.info.src.wellknown) this.info.src.wellknown = '';
        this.loaded = true;
        await this.service.render();
        this.loadAttributes();
    }

    public _url(url) {
        if (this.isAdmin) return `/admin${url}`;
        else return url.replace("/external/", "/mgmt/");
    }

    public data = {
        client_name: "",
        client_id: "",
        client_secret: "",
        endpoints: this.emptyEndpoints(),
        icon: null,
    };
    public wellknown = "";
    public configuration = null;

    public validation(data) {
        if (data.issuer.replace(/\s/g, "").length < 11) return false;
        if (data.client_name.replace(/\s/g, "").length < 1) return false;
        if (data.client_id.replace(/\s/g, "").length < 1) return false;
        if (data.client_secret.replace(/\s/g, "").length < 1) return false;
        return true;
    }

    public async registration() {
        await this.getConfiguration();
        const body = this.service.copy(this.data);
        if (this.configuration.issuer) body.issuer = this.configuration.issuer;
        if (!this.validation(body)) return await this.service.error("Check a required value.");
        body.endpoints = body.endpoints.reduce((acc, [key, val]) => {
            if (val.replace(/\s/g, "").length === 0) return acc;
            acc[key] = val;
            return acc;
        }, {});
        await this.service.loading.show();
        const { code, data } = await wiz.call("registration", body);
        await this.service.loading.hide();
        if (code === 400) {
            return await this.service.error(data);
        }
        if (code === 403) {
            const res = await this.service.alert.show({
                title: "Already registered",
                message: data,
                action: "overwrite",
                cancel: "close",
            });
            if (res) {
                await this.service.loading.show();
                await wiz.call("unregistration", body);
                await this.service.loading.hide();
                await this.registration(body);
            }
            return;
        }
        if (code !== 200) return this.service.error("Faild to registration. Check a required value.");
        await this.service.success("Success to registration.");
        location.href = this._url(`/external/oidc/op/${data}/info`);
        return;
    }

    public redirectURI() {
        return `${location.origin}/rp/callback`;
    }

    public validConfigURL() {
        if (!this.wellknown) return false;
        if (!this.wellknown.startsWith("http")) return false;
        return true;
    }

    public emptyEndpoints() {
        return [
            ["issuer", ""],
            ["authorization_endpoint", ""],
            ["token_endpoint", ""],
            ["userinfo_endpoint", ""],
            ["end_session_endpoint", ""],
            ["jwks_uri", ""],
        ];
    }

    public async getConfiguration() {
        const err = "Failed to get well known configuration";
        if (!this.validConfigURL()) {
            await this.service.error(err);
            throw new Error(err);
        }

        const { code, data } = await wiz.call("configuration", { wellknown: this.wellknown });
        if (code !== 200) {
            this.configuration = false;
            await this.service.error(err);
            throw new Error(err);
        }
        this.configuration = data;
        if (!data) {
            await this.service.error(err);
            throw new Error(err);
        }
        if (this.configuration) {
            await this.service.success("success to call .well-known/openid-configuration!");
            this.data.endpoints = Object.entries(this.configuration).reduce((acc, [key, value]) => {
                const emptyEndpoints = this.emptyEndpoints();
                const validKeys = emptyEndpoints.map(item => item[0]);
                if (!validKeys.includes(key)) return acc;
                const idx = acc.findIndex((item) => item[0] === key);
                if (idx < 0) acc.push([key, value]);
                else acc[idx][1] = value;
                return acc;
            }, this.emptyEndpoints());
        }
        await this.service.render();
    }

    public async onFileSelected(event, isNew = false) {
        this.service.file.resize(event.target.files[0], 60, 1.0)
            .then(base64String => {
                if (!isNew)
                    this.info.icon = base64String;
                else
                    this.data.icon = base64String;
                this.service.render();
            });
    }

    public async update() {
        const body = this.service.copy(this.info);
        const arr = ["owner", "constructor", "created", "updated"];
        arr.forEach(key => {
            delete body[key];
        })
        const { code } = await wiz.call("update", body);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to save");
    }

    public async delete() {
        let res = await this.service.alert.show({
            title: 'Delete OIDC OP',
            message: 'Are you sure?',
            cancel: 'Cancel',
            actionBtn: 'error',
            action: 'Delete',
            status: 'error'
        });

        if (!res) return;

        await wiz.call("delete", this.info);
        this.service.href(this._url("/external/oidc/op"));
    }

    public attributes = [];
    public async loadAttributes() {
        const { code, data } = await wiz.call("attributes");
        if (code !== 200) return;
        this.attributes = data;
        await this.service.render();
    }
}

export default PortalKeycloudExternalOpInfoComponent;