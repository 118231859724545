import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.users.request');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-admin-users-request',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.admin.users.request/view.scss */
@media (max-width: 768px) {
  .actions {
    display: none;
  }
}
.actions .btn,
.actions .form-control {
  height: 32px;
}

table td {
  vertical-align: middle;
}
table tbody tr:last-child td {
  border-bottom: none;
}

.overflow-x {
  min-height: 50vh;
}

.nowrap {
  white-space: nowrap;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .th {
  width: 128px;
  height: 44px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.detail-modal .th {
  width: 150px !important;
  text-transform: none !important;
  overflow-wrap: anywhere;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

textarea {
  resize: none;
}`],
})
export class PageAdminUsersRequestComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public menus = ["pending", "reject", "unregister"];
    public menuMap = {
        pending: "회원가입 승인 대기",
        reject: "회원가입이 반려된 사용자",
        unregister: "회원탈퇴 신청",
    };
    public show(tab) {
        this.search.status = tab;
        try {
            const currentUrl = new URL(`${location.origin}${location.pathname}`);
            currentUrl.searchParams.set("tab", tab);
            history.replaceState({}, "", currentUrl);
        } catch { }
        this.service.render();
    }

    public loaded: boolean = false;
    public list: any = [];

    public search: any = {
        status: 'pending',
        page: 1,
        text: '',
    };

    public pagenation: any = {
        end: 1,
        start: 1
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', "/");
        const params = new URLSearchParams(location.search);
        if (this.menus.includes(params.get('tab'))) this.show(params.get('tab'));
        await this.load();
    }

    public async load(page: number = 1, status = null) {
        this.search.page = page;
        if (status !== null) {
            this.search.status = status;
            this.show(status);
        }
        this.loaded = false;
        this.list = [];
        await this.service.render();
        const { code, data } = await wiz.call("search", this.search);
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.loaded = true;
        await this.service.render();
    }

    public judge = null;
    public async openJudge(user, type) {
        const { code, data } = await wiz.call("request_info", { user_id: user.id, type });
        if (code !== 200) return await this.service.error("Error");
        this.judge = { user, data, response: "" };
        if (user.request && user.request.response) this.judge.response = user.request.response;
        await this.service.render();
    }

    public async judgement(action) {
        if (this.judge.response.replace(/\s/g, "").length === 0)
            return await this.service.error("응답 메세지를 반드시 입력해주세요.");
        if (this.search.status === 'unregister') {
            const txt = action === "active" ? "승인" : "반려";
            const res = await this.service.alert.show({
                title: `회원탈퇴 승인관리: ${txt}`,
                status: "error",
                message: `정말 "${this.judge.user.email}" 회원의 탈퇴를 ${txt}하시겠습니까?`,
                action: txt,
                actionBtn: "error",
                cancel: "취소",
            });
            if (!res) return;
        }
        if (this.search.status === 'pending' && action === "active" && !this.judge.user.email_verified) {
            const res = await this.service.alert.show({
                title: `회원가입 승인 경고`,
                status: "error",
                message: `"${this.judge.user.email}" 회원은 이메일이 인증되지 않았습니다. 정말 가입을 승인하시겠습니까?`,
                action: "승인",
                actionBtn: "error",
                cancel: "취소",
            });
            if (!res) return;
        }
        const body = {
            request_id: this.judge.data.id,
            user_id: this.judge.user.id,
            action,
            response: this.judge.response,
        };
        const { code } = await wiz.call("judge", body);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success(`Success`);
        this.judge = null;
        await this.load(this.search.page);
    }
}

export default PageAdminUsersRequestComponent;