import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.register');
import { OnInit, OnDestroy } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-register',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.register/view.scss */
.wrapper {
  background-image: url(/assets/images/bg-login.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .wrapper {
    overflow-y: auto;
  }
}
.wrapper .intro-area {
  padding: 0 8rem;
}
@media screen and (max-width: 1024px) {
  .wrapper .intro-area {
    padding: 0 4rem;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .intro-area {
    padding: 0 1rem;
  }
}
.wrapper .intro-area .title-area .title {
  font-size: 70px;
}
@media screen and (max-width: 1024px) {
  .wrapper .intro-area .title-area .title {
    font-size: 40px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .intro-area .title-area .title {
    display: inline-block;
    font-size: 28px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .intro-area .title-area .title + .title {
    margin-left: 8px;
  }
}
.wrapper .form-area {
  padding: 0 5rem;
}
@media screen and (max-width: 1024px) {
  .wrapper .form-area {
    padding: 0 3rem;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .form-area {
    padding: 0 1rem;
  }
}
.wrapper .form-area > .card:first-child {
  padding-top: 5rem;
}
@media screen and (max-width: 1024px) {
  .wrapper .form-area > .card:first-child {
    padding-top: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .wrapper .form-area > .card:first-child {
    padding-top: 0.5rem;
  }
}
.wrapper .form-area > .card * {
  border-radius: 10px;
}
.wrapper .form-area > .card.social-card {
  min-height: 300px;
  max-height: 400px;
  overflow-y: auto;
}
.wrapper .base-area {
  min-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 1024px) {
  .wrapper .base-area {
    min-width: 100%;
  }
}
.wrapper .idp-area {
  max-height: 300px;
  overflow-y: auto;
}
.wrapper .idp-area .card:hover {
  border-color: var(--tblr-primary);
  font-family: main-b;
}
.wrapper .input-field + .input-field {
  margin-top: 30px;
}
.wrapper input.form-control {
  height: 42px;
  line-height: 42px;
}`],
})
export class PageRegisterComponent implements OnInit, OnDestroy {
    constructor(@Inject( Service) public service: Service) { }

    public data = {
        email: '',
        password: '',
        firstName: "",
        lastName: "",
    };

    public async ngOnInit() {
        await this.service.init();

        const s = this.service.auth.session;
        await this.service.auth.allow(false, "/authenticate");

        if (!s.idp) {
            location.href = "/auth/logout";
            return;
        }

        const isdupl = s.isdupl;
        if (isdupl) {
            const uniqueKey = s.unique_key;
            const uniqueValue = s.unique_value;
            const res = await this.service.alert.show({
                title: "ID Linking",
                message: `${uniqueKey}: ${uniqueValue}\n위 정보로 이미 가입된 계정이 존재합니다.\n계정을 연결하시겠습니까?`,
                cancel: "취소",
                actionBtn: "error",
                action: "계정 연결하기",
                status: "error"
            });
            if (res) {
                this.linking = true;
                location.href = "/authenticate";
                return;
            }
        }

        const ui = s.requested_userinfo;
        this.data.email = ui.email;
        this.data.username = ui.email.split("@")[0];
        if (ui.lastName) this.data.lastName = ui.lastName;
        if (ui.firstName) this.data.firstName = ui.firstName;
        await this.service.render();
        this.captchaRefresh();
        await this.service.success("모든 회원은 로컬 계정이 등록되어있어야 합니다.\n로컬 계정 회원가입을 진행합니다.");
    }

    public ngOnDestroy() {
        if (!this.linking)
            wiz.call("session_clear");
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async join() {
        if (this.data.firstName.length < 1) return await this.alert("이름을 입력해주세요");
        if (this.data.lastName.length < 1) return await this.alert("성을 입력해주세요");

        let password = this.data.password;
        let password_re = this.data.password_repeat;
        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        if (!this.data.purpose) return await this.service.error("가입목적을 입력해주세요.");

        let user = this.service.copy(this.data);
        delete user.password_repeat;
        user.password = this.service.auth.hash(user.password);
        delete user.onetimepass;
        if (!user.username) return await this.service.error("ID를 입력해주세요.");

        let { code, data } = await wiz.call("join", user);

        if (code == 200) {
            await this.alert("회원가입 신청이 완료되었습니다.\n관리자의 승인 후 로그인할 수 있습니다.", "success");
            location.reload();
        } else {
            await this.alert(data);
        }
    }

    public captcha = null;
    public async captchaRefresh() {
        this.captcha = null;
        await this.service.render(100);
        this.captcha = this.captchaURL();
        await this.service.render();
    }
    public captchaURL() {
        return wiz.url(`captcha?ts=${+new Date()}`);
    }
}

export default PageRegisterComponent;