import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /opt/keycloud/project/main/build/src/app/page.mypage/view.scss */
.wrapper > .col-md-6:first-child {
  padding-right: 12px;
}
.wrapper > .col-md-6:last-child {
  padding-left: 12px;
}

.btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

.input-field + .input-field {
  margin-top: 20px;
}

i.fa-solid.fa-xmark.text-red {
  vertical-align: middle;
}

.wiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: inline-block;
  width: auto;
  height: auto;
  position: relative;
}
.wiz-modal .modal-content {
  width: 1160px;
  padding: 20px;
  max-height: 1031px;
  overflow-y: auto;
  background-color: #E9E8EE;
}
.wiz-modal .modal-content .title {
  font-family: main-b;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content th {
  background: transparent;
}
.wiz-modal .modal-content .form-selectgroup-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 137px;
  height: 44px;
  margin-right: 10px;
  box-shadow: none;
}
.wiz-modal .modal-content .form-control,
.wiz-modal .modal-content .form-select {
  border-radius: 15px;
  width: auto;
  flex-grow: 1 !important;
}

.table tbody tr:first-child {
  border-top: 1px solid black;
}
.table tbody tr th {
  width: 200px;
  background-color: #efefef;
  color: #707070;
  align-content: center;
}
.table.attr-table td {
  padding: 10px;
  align-content: center;
}
.table.attr-table td > div {
  height: 44px;
  padding-left: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  color: var(--kc-t1);
  font-size: 1rem;
  align-content: center;
}
.table.attr-table td .btn-plus {
  border: 2px dashed var(--tblr-primary);
  background: #EDF8FF 0% 0% no-repeat padding-box;
}

textarea {
  resize: none;
}`],
})
export class PageMypageComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public modal = {
        password: false,
        unregister: false,
    };

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/authenticate");
        const s = this.service.auth.session;
        if (s.linking_error) {
            await this.service.error(s.linking_error);
        }
        await this.service.render();
        await this.load();
        await this.getLinked();
        if (!this.isAdmin()) await this.getRequest();
    }

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "Confirm",
            status: status
        });
    }

    public sub = null;

    public async load() {
        const { code, data } = await wiz.call("session");
        if (code !== 200) return await this.service.error("SERVER ERROR");
        const { attributes, hasPassword, sub } = data;
        this.attrs = attributes;
        this.hasPassword = hasPassword;
        this.sub = sub;
        await this.service.render();
    }

    public attrs: any = [];
    public onChangeAttribute(e, attr, i) {
        attr.value[i] = e.target.value;
    }
    public async updateAttributes() {
        const body = this.attrs.reduce((acc, { key, value }) => {
            acc[key] = value;
            return acc;
        }, {});
        const { code } = await wiz.call("update_attributes", body);
        if (code !== 200) return await this.service.error("Error");
        this.service.success("Success to update user attributes");
        await this.load();
        await this.service.auth.init();
    }

    public listOpt = {
        // handle: '.handle',
        animation: 150,
    };

    public hasPassword = false;
    public password = {
        current: "",
        data: "",
        repeat: "",
    };
    public async changePassword() {
        const pdata = this.service.copy(this.password);
        if (pdata.data !== pdata.repeat) {
            await this.alert("Check password");
            return;
        }

        let pd = {};
        pd.current = this.service.auth.hash(pdata.current);
        pd.data = this.service.auth.hash(pdata.data);

        await this.service.loading.show();
        const { code, data } = await wiz.call("change_password", pd);
        await this.service.loading.hide();
        if (code !== 200) return await this.alert(data);
        await this.service.success("Success to change password. Please login again.");

        location.href = "/auth/logout?returnTo=/authenticate";
    }

    public isAdmin() {
        if (this.service.auth.session.role === 'admin') return true;
        return false;
    }

    public request = {};
    public async getRequest() {
        const { code, data } = await wiz.call("request_info");
        if (code !== 200) return;
        this.request = data;
        await this.service.render();
    }

    public async unregister() {
        if (!this.request.purpose) return;
        const { code } = await wiz.call("unregister", this.request);
        if (code !== 200) return await this.service.error("Error");
        await this.service.success("회원탈퇴 신청이 완료되었습니다.");
        this.modal.unregister = false;
        await this.service.render();
    }

    public linked = [];
    public async getLinked() {
        const { code, data } = await wiz.call("linked");
        if (code !== 200) return;
        this.linked = data;
        await this.service.render();
    }
    public async linking() {
        await wiz.call("link_start");
        location.href = "/authenticate";
    }
    public async removeLink(item) {
        const res = await this.service.alert.show({
            title: "계정 연결 취소",
            message: `${item.idpname} - ${item.email} 이 계정에 대한 연결을 해제하시겠습니까?`,
            cancel: "취소",
            actionBtn: "error",
            action: "연결 해제",
            status: "error"
        });
        if (!res) return;
        const { code } = await wiz.call("link_remove", { id: item.id });
        if (code !== 200) return await this.service.error("Error");
        await this.getLinked();
    }
}

export default PageMypageComponent;